export const MetaStrings = {
    "home": {
        "title": "BAPS Shri Swaminarayan Mandir, Chicago",
        "description": "The BAPS Shri Swaminarayan Mandir of Chicago, Illinois is a traditional Hindu place of worship built by the BAPS Swaminarayan Sanstha.",
        "keywords": "chicago mandir, baps mandir, baps temple, akshardham, baps chicago, baps chicago mandir, swaminarayan temple bartlett, baps chicago events, baps better living, timeless hindu wisdom",
        "url": "https://chicagomandir.org",
        "imageURL": "https://chicagomandir.org/static/media/timeless-background.b9904e71.jpeg"
    },
    "events": {
        "title": "Upcoming Events",
        "description": "BAPS Shri Swaminarayan Mandir, Chicago, IL cordially invites you, your family and friends to join us in celebrating these joyous occasions.",
        "keywords": "hindu events, baps mandir, sunday assembly, sabha, diwali, hindu new year, diwali 2023, diwali chicago, chicago mandir, baps chicago, baps temple, baps chicago mandir, swaminarayan temple bartlett, baps chicago events, baps better living, timeless hindu wisdom",
        "url": "https://chicagomandir.org/events",
        "imageURL": "https://chicagomandir.org/static/media/timeless-background.b9904e71.jpeg"
    },
    "thw": {
        "title": "Timeless Hindu Wisdom",
        "description": "Welcome to the information site for the BAPS Timeless Hindu Wisdom Sessions at the BAPS Shri Swaminarayan Mandir, Bartlett, IL. These sessions are presented by learned Hindu Swamis on introductory spiritual topics. These topics are presented each month in English. Everyone is welcome to attend these lectures free of charge.",
        "keywords": "chicago mandir, baps mandir, baps temple, baps chicago, baps chicago mandir, swaminarayan temple bartlett, baps chicago events, baps better living, timeless hindu wisdom, hindu lectures chicago, hinduism",
        "url": "https://chicagomandir.org/timelesshinduwisdom",
        "imageURL": "https://chicagomandir.org/static/media/timeless-background.b9904e71.jpeg"
    },
    "diwali": {
        "title": "Diwali Celebrations",
        "description": "Diwali is a multi-faith and multi-day festival which is joyously celebrated across BAPS Mandirs in North America. Diwali also known as the ‘festival of lights’ was observed by devotees at mandirs and at home, embracing traditions of reflection, appreciation, positivity, and the strengthening of familial, communal, and spiritual bonds.",
        "keywords": "diwali, hindu new year, baps mandir, diwali 2023, diwali chicago, chicago mandir, baps chicago, baps chicago mandir, swaminarayan temple bartlett, baps chicago events, baps better living, timeless hindu wisdom",
        "url": "https://chicagomandir.org/diwali",
        "imageURL": "https://chicagomandir.org/static/media/diwali_mainpage.1a5e2456.jpg"
    },
    "notFound": {
        "title": "Page Not Found",
        "description": "The page you are looking for is not found.",
        "keywords": "",
        "url": "",
        "imageURL": ""
    },
    "checkinPage": {
        "title": "Event Check-In",
        "description": "The BAPS Shri Swaminarayan Mandir of Chicago, Illinois is a traditional Hindu place of worship built by the BAPS Swaminarayan Sanstha.",
        "keywords": "",
        "url": "",
        "imageURL": "https://chicagomandir.org/static/media/timeless-background.b9904e71.jpeg"
    },
    "campusDiwali": {
        "title": "Campus Diwali Celebrations",
        "description": "BAPS Campus Fellowship cordially invites you, your family and friends to join us in celebrating the festival of Diwali with us.",
        "keywords": "diwali, hindu new year, campus diwali, uic diwali, iit diwali, depaul diwali, baps mandir, diwali 2023, diwali chicago, chicago mandir, baps chicago, baps chicago mandir, swaminarayan temple bartlett, baps chicago events, baps better living, timeless hindu wisdom",
        "url": "https://chicagomandir.org/campus-diwali",
        "imageURL": "https://chicagomandir.org/static/media/diwali_mainpage.1a5e2456.jpg"
    },
    "padharamni": {
        "title": "Sant Padharamni",
        "description": "The BAPS Shri Swaminarayan Mandir of Chicago, Illinois is a traditional Hindu place of worship built by the BAPS Swaminarayan Sanstha.",
        "keywords": "",
        "url": "",
        "imageURL": ""
    }
};