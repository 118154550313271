// Vendor Files
import React from "react";
import { Container, Row, Col, Button, Navbar, Nav, Tabs, Tab, Card, Form, Modal, Alert } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';

// Custom Components
import Footer from "../components/Footer"

// Util
import MetaDecorator from "../util/MetaDecorator";
import { MetaStrings } from "../util/MetaStrings";

// Image Files
import telegram_logo from "../assets/logos/telegram.png";
import logo from "../assets/logos/better-living-logo.png";
import clock_icon from "../assets/images/clock-icon.png";
import location_icon from "../assets/images/location-icon.png";
import ticket_icon from "../assets/images/ticket.png";
import psm_quote from "../assets/images/psm_quote.png";

// https://drive.google.com/file/d/16JmyGZnVSa2ya4_pkjvVGHf3yAYFoNt2/view?usp=share_link
// https://drive.google.com/uc?export=view&id=0B6wwyazyzml-OGQ3VUo0Z2thdmc

export default class TimelessHinduWisdom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionsList: [],
            sessionsError: false,

            showRegisterModal: false,
            rsvpName: "",
            rsvpEmail: "",
            rsvpPhone: "",
            rsvpTelegramConfirm: "",
            rsvpDay: "",
            rsvpRegister: "",
            rsvpFamilyCount: "",
            rsvpChildCount: "",
            rsvpWarningText: "",
            rsvpDisableRegisterBtn: false,
            rsvpShowSuccessMsg: false,
            rsvpShowFormWarning: false,

            showContactModal: false,
            contactName: "",
            contactEmail: "",
            contactCategory: "",
            contactSubject: "",
            contactMessage: "",
            contactWarningText: "",
            contactDisableRegisterBtn: false,
            contactShowSuccessMsg: false,
            contactShowFormWarning: false,

            showPastSessions: false,
            pastSessionsList: [],
            pastSessionsError: false,
        };
    }

    componentDidMount() {
        this.fetchSessions();
        this.fetchPastSessions();
    }

    fetchSessions() {
        fetch(
            "https://sheets.googleapis.com/v4/spreadsheets/1bHVnT8SRGN8OUYlg1QxKOtpSYAkIjVG6J7YvZSxlxXY/values/Sessions?key=AIzaSyBv5LPMYVjJ0gttyVyROpQD5xO0nvMy2So"
        )
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.values.length > 1) {
                    let sessions = result.values;
                    sessions.shift();
                    this.setState({sessionsList: sessions});
                }
            },
            (error) => {
                this.setState({
                    sessionsError: true,
                });
            }
        );
    }

    fetchPastSessions() {
        fetch(
            "https://sheets.googleapis.com/v4/spreadsheets/1bHVnT8SRGN8OUYlg1QxKOtpSYAkIjVG6J7YvZSxlxXY/values/Past%20Sessions?key=AIzaSyBv5LPMYVjJ0gttyVyROpQD5xO0nvMy2So"
        )
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.values.length > 1) {
                    let sessions = result.values;
                    sessions.shift();
                    this.setState({pastSessionsList: sessions});
                }
            },
            (error) => {
                this.setState({
                    pastSessionsError: true,
                });
            }
        );
    }

    handleClose() {
        this.setState({
            showRegisterModal: false,
            showContactModal: false,
            showPastSessions: false,

            rsvpDisableRegisterBtn: false,
            rsvpShowSuccessMsg: false,

            contactDisableRegisterBtn: false,
            contactShowSuccessMsg: false,
        });
    }
    handleOpen(modalNum, options = {}) {
        if (modalNum === 1) {
            this.setState({
                showRegisterModal: true,
                rsvpDay: options['rsvpDay'] ? options['rsvpDay'] : ""
            });
        }
        else if (modalNum === 2){
            this.setState({
                showContactModal: true
            });
        }
        else {
            this.setState({
                showPastSessions: true
            });
        }
    }

    handleResetFormFields() {
        this.setState({
            rsvpName: "",
            rsvpEmail: "",
            rsvpPhone: "",
            rsvpTelegramConfirm: "",
            rsvpRegister: "",
            rsvpFamilyCount: "",
            rsvpChildCount: "",
            rsvpWarningText: "",

            contactName: "",
            contactEmail: "",
            contactCategory: "",
            contactSubject: "",
            contactMessage: "",
            contactWarningText: "",
        });
      }

    handleRSVPRegister() {
        // Check fields are filled
        let unfilledFields = this.state.rsvpName === "" || this.state.rsvpEmail === "" || this.state.rsvpTelegramConfirm === "" || this.state.rsvpRegister === "";
        if (unfilledFields === true) {
            this.setState({ rsvpWarningText: "Please fill out all the required fields", rsvpShowFormWarning: true });
            return;
        }

        // Check email is in correct format
        if (!this.validateEmail(this.state.rsvpEmail)) {
            this.setState({ rsvpWarningText: "Please enter a valid email.", rsvpShowFormWarning: true });
            return;
        }

        // let url = "http://localhost:5005/api/thw/rsvp" // Dev testing
        let url = "https://mw-chicago-mandir-backend.web.app/api/thw/rsvp" // Production
        let body = {
            name: this.state.rsvpName,
            email: this.state.rsvpEmail,
            phone: this.state.rsvpPhone,
            telegramConfirm: this.state.rsvpTelegramConfirm,
            rsvpDay: this.state.rsvpDay,
            rsvp: this.state.rsvpRegister,
            familyCount: this.state.rsvpFamilyCount,
            childCount: this.state.rsvpChildCount
        }
        this.setState({ rsvpDisableRegisterBtn: true });
        axios.post(url, body).then((res) => {
          if (res.data.success) {
            this.setState({ rsvpDisableRegisterBtn: false, rsvpShowSuccessMsg: true, rsvpShowFormWarning: false });
            this.handleResetFormFields();
          }
          else {
            this.setState({ rsvpDisableRegisterBtn: false });
            this.setState({ rsvpWarningText: "An error occured while sending your information. Please try again.", rsvpShowFormWarning: true });
          }
        });
    }

    handleContactForm() {
        // Check fields are filled
        let unfilledFields = this.state.contactSubject === "" || this.state.contactMessage === "";
        if (unfilledFields === true) {
            this.setState({ contactWarningText: "Please fill out all the required fields", contactShowFormWarning: true });
            return;
        }

        // let url = "http://localhost:5005/api/thw/contact" // Dev testing
        let url = "https://mw-chicago-mandir-backend.web.app/api/thw/contact" // Production
        let body = {
            name: this.state.contactName,
            email: this.state.contactEmail,
            category: this.state.contactCategory,
            subject: this.state.contactSubject,
            message: this.state.contactMessage
        }
        this.setState({ contactDisableRegisterBtn: true });
        axios.post(url, body).then((res) => {
          if (res.data.success) {
            this.setState({ contactDisableRegisterBtn: false, contactShowSuccessMsg: true, contactShowFormWarning: false });
            this.handleResetFormFields();
          }
          else {
            this.setState({ contactDisableRegisterBtn: false });
            this.setState({ contactWarningText: "An error occured while sending your information. Please try again.", contactShowFormWarning: true });
          }
        });
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    render() {
       
        return (
            <div>
                <MetaDecorator 
                    title={MetaStrings.thw.title}
                    description={MetaStrings.thw.description}
                    keywords={MetaStrings.thw.keywords}
                    url={MetaStrings.thw.url}
                    imageURL={MetaStrings.thw.imageURL}
                >
                </MetaDecorator>

                <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top">
                    <Container>
                        <Navbar.Brand href="/timelesshinduwisdom">
                            <img alt="" src={logo} className="title-image" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                            <Nav>
                                <Nav.Link href="#upcoming-sessions">Upcoming Sessions</Nav.Link>
                                {/* <Nav.Link href="#location">Location</Nav.Link> */}
                                <Nav.Link onClick={() => {this.handleOpen(2)}}>Contact Us</Nav.Link>
                                <Nav.Link href="https://www.baps.org/chicago" target="_blank">About Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <div className="headerSection">
                    <div className="headerContent">
                        <p>BAPS Chicago Presents</p>
                        <h1>Timeless Hindu Wisdom</h1>
                        <p>Monthly sesssions featuring interactive presentations and insightful talks by learned Swamis of BAPS.</p>
                    </div>
                </div>

                <div className="sectionCont">
                    <Container>
                        <h2>Session Information</h2>
                        <p>Namaste! Welcome to the information site for the BAPS Timeless Hindu Wisdom Sessions at the BAPS Shri Swaminarayan Mandir, Bartlett, IL. These sessions are presented by learned Hindu Swamis on introductory spiritual topics. These topics are presented each month in English. Everyone is welcome to attend these lectures free of charge.</p>
                        <div className={"gridCont visitorCont"}>
                            <Row className="sessionsInfoCont">
                                <Col md={4} className="">
                                    <img alt="" src={clock_icon} />
                                    <h3>Timing</h3>
                                    <p>
                                        Third Sunday of the Month, 11:45a to 1:30p <br /> 
                                        <b>Darshan</b> - 11:30a in Mandir <br />
                                        <b>Lunch</b> - 11:45a in Viewing Cabin, YAC <br />
                                        <b>Session</b> - 12:15p - 1:30p in Multipurpose Room, YAC
                                    </p>
                                </Col>
                                <Col md={4} className="">
                                    <img alt="" src={location_icon} />
                                    <h3>Address</h3>
                                    <p>
                                        BAPS Shri Swaminarayan Mandir <br />
                                        1851 S IL Route 59 <br /> 
                                        Bartlett, Illinois 60103
                                    </p>
                                </Col>
                                <Col md={4} className="">
                                    <img alt="" src={ticket_icon} />
                                    <h3>Free Entry</h3>
                                    <p>People of all faiths and backgrounds are welcome to participate and invite their friends and family members</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a href="https://t.me/+Rk1iR9iga8Dxj-9p" target="_blank" rel="noreferrer"
                                    style={{
                                        color: "#136cce",
                                        textDecoration: "none",
                                        display: "inline-block"
                                    }}
                                    >
                                    <div style={{ display: "flex" }}>
                                        <h3 style={{ color: "#1b1e2f" }}>Join the Telegram Group:</h3>
                                        <img
                                        className="whatsapp-app-icon"
                                        srcSet={telegram_logo + " 1280w"}
                                        sizes="300px"
                                        src={telegram_logo}
                                        alt="Share via Telegram"
                                        />
                                    </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <div className="divider-line"></div>

                <div id="upcoming-sessions" className="sectionCont">
                    <Container>
                        <h2>Upcoming Sessions</h2>
                        <div className={"gridCont sessions-tab"}>
                            {this.state.sessionsList.length > 0 ?
                            <Tabs id="noanim-tab-example">
                                {this.state.sessionsList.map((event, index) => {
                                    return event[9] === "Yes" ?
                                        <Tab eventKey={event[0]} title={event[0]}>
                                            <Row>
                                                <Col md={6} className="">
                                                    <h3>{event[1]}</h3>
                                                    <p>
                                                        <b>Date:</b> {event[2]} <br/>
                                                        <b>Time:</b> {event[3]} <br/>
                                                        <b>Speaker:</b> {event[4]} <br/>
                                                        <b>Location:</b> {event[5]} <br/>
                                                    </p>
                                                    <p>
                                                        <b>About This Session:</b> <br/>
                                                        {event[6]}
                                                    </p>
                                                    {event[8] === "Yes" ? 
                                                        <Button target="_blank" onClick={() => {this.handleOpen(1, {"rsvpDay": event[2]})}}>Register Now</Button>
                                                    : ""}
                                                </Col>
                                                <Col md={6} className="centerCont">
                                                    <div>
                                                        <img className="grid-event-img" src={event[7]} alt={event[1]} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    : ""
                                })}
                            </Tabs>
                            : 
                            <p>{this.state.sessionsError ? 
                                "We are having issues fetching upcoming sessions. Please try again later." : ""
                            }</p>
                        }
                        </div>
                        <div className="past-sessions">
                            <p>To learn about our past sessions, click below</p>
                            <Button target="_blank" onClick={() => {this.handleOpen(3)}}>View Past Sessions</Button>
                        </div>
                    </Container>
                </div>

                <div className="parallaxSection">
                    <Container className="bannerCont">
                        <img src={psm_quote} alt="In the joy of others lies our own, In the progress of others rests our own, In the good of others abides our own, Know this to be the key to peace and happiness" />
                    </Container>
                </div>

                <Footer></Footer>

                <Modal show={this.state.showRegisterModal} onHide={() => this.handleClose()} size="lg" className="modalCont" scrollable={true}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="modalHeader">
                            <h2>Register for the Session</h2>
                            <p>Fill out form below to register for the event. We look forward to seeing you and your family.</p>
                        </div>
                        {this.state.rsvpShowFormWarning ? <Alert variant={"danger"}>{this.state.rsvpWarningText}</Alert> : ""}
                        {this.state.rsvpShowSuccessMsg ? <Alert variant={"success"}>Thank you for filling out your information. We have successfully registered you for the session.</Alert> : ""}
                        {!this.state.rsvpShowSuccessMsg ?
                        <Form autoComplete="disabled">
                                <Form.Group className="mb-3">
                                    <Form.Label>What is your name?<sup>*</sup></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your name"
                                        value={this.state.rsvpName}
                                        onChange={(event) => this.setState({ rsvpName: event.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>What is your email?<sup>*</sup></Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        value={this.state.rsvpEmail}
                                        onChange={(event) => this.setState({ rsvpEmail: event.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>What is your phone number?</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter your phone number"
                                        value={this.state.rsvpPhone}
                                        onChange={(event) => this.setState({ rsvpPhone: event.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Are you part of our telegram group?<sup>*</sup></Form.Label>
                                    <Form.Control as="select" value={this.state.rsvpTelegramConfirm} onChange={(event) => this.setState({ rsvpTelegramConfirm: event.target.value })}>
                                        <option className="hideOption"></option>
                                        <option disabled>Pick from below:</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Will you be attending this Session?<sup>*</sup></Form.Label>
                                    <Form.Control as="select" value={this.state.rsvpRegister} onChange={(event) => this.setState({ rsvpRegister: event.target.value })}>
                                        <option className="hideOption"></option>
                                        <option disabled>Pick from below:</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Will any of your family members be attending? If so, how many?</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter number of family members attending"
                                        value={this.state.rsvpFamilyCount}
                                        onChange={(event) => this.setState({ rsvpFamilyCount: event.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Will you be bringing children? If so, how old are they?</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter number of children attending"
                                        value={this.state.rsvpChildCount}
                                        onChange={(event) => this.setState({ rsvpChildCount: event.target.value })}
                                    />
                                </Form.Group>
                            </Form>
                        : ""}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                            Close
                        </Button>
                        {!this.state.rsvpShowSuccessMsg ?
                        <div>
                            <Button variant="primary" onClick={() => this.handleRSVPRegister()} disabled={this.state.rsvpDisableRegisterBtn}>
                            Register Now
                            </Button>
                        </div>
                        : ""}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showContactModal} onHide={() => this.handleClose()} size="lg" className="modalCont" scrollable={true}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="modalHeader">
                            <h2>Feedback / Contact Us</h2>
                            <p>We want to hear your feedback? Do have a suggestion? Feel free to use the box below to submit your feedback. Optionally, If you would like for us to contact you back feel free to provide your name and contact information.</p>
                        </div>
                        {this.state.contactShowFormWarning ? <Alert variant={"danger"}>{this.state.contactWarningText}</Alert> : ""}
                        {this.state.contactShowSuccessMsg ? <Alert variant={"success"}>Thank you for filling out the form. We have successfully received your feedback.</Alert> : ""}
                        {!this.state.contactShowSuccessMsg ?
                        <Form autoComplete="disabled">
                                <Form.Group className="mb-3">
                                    <Form.Label>Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your full name"
                                        value={this.state.contactName}
                                        onChange={(event) => this.setState({ contactName: event.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        value={this.state.contactEmail}
                                        onChange={(event) => this.setState({ contactEmail: event.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Category:</Form.Label>
                                    <Form.Control as="select" value={this.state.contactCategory} onChange={(event) => this.setState({ contactCategory: event.target.value })}>
                                        <option className="hideOption"></option>
                                        <option disabled>Pick from below:</option>
                                        <option>General Session Feedback (e.g., Flow, Registration, Lunch, etc.)</option>
                                        <option>Session Talk Feedback</option>
                                        <option>Report a Bug (with site or forms)</option>
                                        <option>Topic Suggestion</option>
                                        <option>General Question/Inquiry</option>
                                        <option>Other</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Subject:<sup>*</sup></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your subject"
                                        value={this.state.contactSubject}
                                        onChange={(event) => this.setState({ contactSubject: event.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Message:<sup>*</sup></Form.Label>
                                    <Form.Control
                                        as="textarea" rows={3}
                                        type="text"
                                        placeholder="Enter your message"
                                        value={this.state.contactMessage}
                                        onChange={(event) => this.setState({ contactMessage: event.target.value })}
                                    />
                                </Form.Group>
                            </Form>
                        : ""}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                            Close
                        </Button>
                        {!this.state.contactShowSuccessMsg ?
                        <div>
                            <Button variant="primary" onClick={() => this.handleContactForm()} disabled={this.state.contactDisableRegisterBtn}>
                            Submit
                            </Button>
                        </div>
                        : ""}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showPastSessions} onHide={() => this.handleClose()} size="lg" className="modalCont" scrollable={true}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="modalHeader">
                            <h2>Past Sessions</h2>
                            <p>Click each topic below to learn more</p>
                        </div>
                        <Accordion>
                        {this.state.pastSessionsList.map((event, index) => {
                            return event[2] ? 
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey={index+1}>
                                        <b>{event[1]}</b>
                                        <span></span>
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey={index+1}>
                                        <Card.Body>
                                            {event[2]}
                                            {event[4] && 
                                                <div className="iframesCont">
                                                    {event[5] === "Instagram" &&
                                                        <div className="iframeCont">
                                                            <iframe title={event[1]} className="reelFrame" src={event[4]} allowtransparency="true" frameborder="0" scrolling="no"></iframe>
                                                        </div>
                                                    }
                                                    {event[5] === "Spotify" &&
                                                        <div className="iframeContSpotify">
                                                            <iframe title={event[1]} src= {event[4].trim() + "?theme=0"} allowtransparency="true" frameborder="0" scrolling="no"></iframe>
                                                        </div>
                                                    }
                                                    {event[5] === "YouTube" && 
                                                        <div className="youtube-video-container">
                                                            <div className="youtube-video-ratio">
                                                                <iframe
                                                                className="youtube-video"
                                                                src={event[4].trim()}
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen
                                                                title={event[1]}
                                                                ></iframe>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </Card.Body>

                                    </Accordion.Collapse>
                                </Card>
                            : ""
                        })}
                        </Accordion>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
