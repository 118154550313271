export const bookingList = {
    'sant-mulakat': {
        'name': 'Pu. Tyagswami Mulakat',
        'emailSubject': 'Vyaktigat Mulakat with Sadguru Pujya Tyagvallabh Swami',
        'description': 'Sadguru Pujya Tyagvallabh swami\'s mulakat  is for seeking guidance on your Aahnik (Niyam, Dharma in Satsang) and Vyavaarik (family) related questions. Select the time slot appropriate from below. If the assigned date is not convinient then reach out to your SO karyakar or Zone Leader.',
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'guestsAllowed': true,
        'active': false
    },
    'samarpan-mulakat': {
        'name': 'One on one Mulakat with Pujya Priyaseva Swami',
        'emailSubject': 'One on one Mulakat with Pujya Priyaseva Swami',
        'bcc': 'so.ndc2@na.baps.org',
        'description': 'Select the time slot appropriate from below. If the assigned date is not convinient then reach out to your SO karyakar.',
        'location': "Priyaseva Swami's office: Haveli third floor, hallway across glass door conference room",
        'breakTimes': ["9:00 AM", "9:15 AM", "9:30 AM", "9:45 AM", "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM", "11:00 AM", "11:15 AM", "11:30 AM", "11:45 AM", "12:00 PM", "12:15 PM", "12:30 PM", "12:45 AM", "1:00 PM", "1:15 PM", "1:30 PM", "1:45 PM", "2:00 PM", "2:15 PM", "2:30 PM", "2:45 PM", "3:00 PM", "3:15 PM", "3:30 PM", "3:45 PM", "4:00 PM", "4:15 PM", "4:30 PM", "4:45 PM", "5:00 PM", "5:15 PM", "5:30 PM", "5:45 PM", "6:00 PM", "6:15 PM", "6:30 PM", "6:45 PM", "7:00 PM", "7:15 PM", "7:30 PM", "7:45 PM", "8:00 PM", "8:15 PM", "8:30 PM", "8:45 PM", "9:00 PM", "9:15 PM", "9:30 PM", "9:45 PM", "10:00 PM"],
        'active': true
    },
    'samarpan-mulakat-sant': {
        'name': 'One on one Mulakat with Pujya Pragnanand Swami',
        'emailSubject': 'One on one Mulakat with Pujya Pragnanand Swami',
        'bcc': ['so.ndc2@na.baps.org'],
        'description': 'Select the time slot appropriate from below. If the assigned date is not convinient then reach out to your SO karyakar.',
        'location': "Pragnanand Swami's office: Haveli third floor, second hallway on left, last room on right",
        'breakTimes': ["9:00 AM", "9:15 AM", "9:30 AM", "9:45 AM", "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM", "11:00 AM", "11:15 AM", "11:30 AM", "11:45 AM", "12:00 PM", "12:15 PM", "12:30 PM", "12:45 AM", "1:00 PM", "1:15 PM", "1:30 PM", "1:45 PM", "2:00 PM", "2:15 PM", "2:30 PM", "2:45 PM", "3:00 PM", "3:15 PM", "3:30 PM", "3:45 PM", "4:00 PM", "4:15 PM", "4:30 PM", "4:45 PM", "5:00 PM", "5:15 PM", "5:30 PM", "5:45 PM", "6:00 PM", "6:15 PM", "6:30 PM", "6:45 PM", "7:00 PM", "7:15 PM", "7:30 PM", "7:45 PM", "8:00 PM", "8:15 PM", "8:30 PM", "8:45 PM", "9:00 PM", "9:15 PM", "9:30 PM", "9:45 PM", "10:00 PM"],
        'active': true
    },
};