// Vendor Files
import React from "react";
import { useParams } from "react-router";

// Custom Components
import Header from "../components/Header"
import Footer from "../components/Footer"
import NotFoundContent from "../components/NotFoundContent"

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>

                <Header metaPage="notFound"></Header>

                <NotFoundContent></NotFoundContent>

                <Footer></Footer>
            </div>
        );
    }
}

export default withParams(NotFound);
