// Vendor Files
import React from "react";
import { Container, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router";

// Events List
import { eventsList } from './checkInEvents';

// Custom Components
import Header from "../../components/Header"
import NotFoundContent from "../../components/NotFoundContent"

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class CheckInPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selEvent: "",
            eventNotFound: false,
            email: "",
            showWarning: false,
            showSuccess: false,
            warningText: "",
            disableCheckInBtn: false
        };
    }

    componentDidMount() {
        let { event_name } = this.props.params;
        if (event_name) {
            if (event_name in eventsList && eventsList[event_name].active) {
                this.setState({email: "", selEvent: eventsList[event_name]});
            }
            else {
                this.setState({eventNotFound: true})
            }
        }
    }

    handleCheckIn(event) {
        event.preventDefault();
        // Check email is in correct format
        if (!this.state.selEvent.notEmail & !this.validateEmail(this.state.email)) {
            this.setState({ warningText: "Please enter a valid email.", showWarning: true });
            return;
        }

        // let url = `http://localhost:5004/api/checkin/${this.state.selEvent.name}/${this.state.email}` // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/checkin/${this.state.selEvent.name}/${this.state.email}` // Production

        this.setState({ disableCheckInBtn: true });
        axios.post(url, {}).then((res) => {
            if (res.data.data.duplicate) {
                this.setState({ disableCheckInBtn: false });
                this.setState({ warningText: "Already checked in", showWarning: true });
            }
            else if (res.data.data.affectedRows > 0) {
                this.setState({ disableCheckInBtn: false, showSuccess: true, showWarning: false });
                setTimeout(() => {
                    this.setState({showSuccess: false})
                  }, 3000);
                this.setState({ email: "" });
            }
            else {
                this.setState({ disableCheckInBtn: false });
                this.setState({ warningText: "An error occured while sending your information. Please try again.", showWarning: true });
            }
        });
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    sampleClick() {
        alert("hello");
    }

    render() {
        return (
            <div id="pageCont">

                <Header metaPage="checkinPage"></Header>
                    {!this.state.eventNotFound ?
                        <div className="sectionCont fullHeight">
                            <Container>
                                <h1 className="title">{this.state.selEvent.displayName}</h1>

                                <p><b>Date:</b> {this.state.selEvent.eventDate} <br/> <b>Time:</b> {this.state.selEvent.eventTime}</p>
                                <p>Please scan attendee's barcode below, or enter their {this.state.selEvent.notEmail ? "id" : "email"} manually if they don't have a barcode.</p>
                                <br />
                                <Form autoComplete="disabled" className="checkinForm" action={void(0)} onSubmit={(event) => this.handleCheckIn(event)}>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder={this.state.selEvent.notEmail ? "Scan/Enter attendee's Id" : "Scan/Enter attendee's email"}
                                            value={this.state.email}
                                            onChange={(event) => this.setState({ email: event.target.value })}
                                            onInput={(event) => this.setState({ email: event.target.value })}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" disabled={this.state.disableCheckInBtn}>
                                        Submit
                                    </Button>
                                </Form>
                                {this.state.showSuccess ? <Alert variant={"success"}>You are checked in. Thank you!</Alert> : ""}
                                {this.state.showWarning ? <Alert variant={"danger"}>{this.state.warningText}</Alert> : ""}
                            </Container>
                        </div>
                    : <NotFoundContent></NotFoundContent>}
            </div>
        );
    }
}

export default withParams(CheckInPage);