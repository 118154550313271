// Vendor Files
import React from "react";
import { Button, Form, Table, Alert, Modal, Card } from 'react-bootstrap';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import { useParams } from "react-router";
import * as moment from 'moment-timezone';
import { Link45deg, Clipboard2CheckFill } from 'react-bootstrap-icons';

// Image Files
import pencil_icon from "../../assets/images/pencil.png";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class PadharamniZoneList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            padharamniList: [],
            editPadharamniDetails: {},
            registerDisableBtn: false,
            registerWarningText: "",
            registerShowWarning: false,
            displayCopyModal: false
        };
    }

    componentDidMount() {
        this.setState({ padharamniList: this.props.tableList });
    }

    editPadharamni(padharamniDetails) {
        this.handleResetValues();
        let padharamni = { ...padharamniDetails };
        padharamni.KaryakarName1 = padharamni.KaryakarName1 ? padharamni.KaryakarName1 : "";
        padharamni.KaryakarCell1 = padharamni.KaryakarCell1 ? padharamni.KaryakarCell1 : "";
        padharamni.KaryakarName2 = padharamni.KaryakarName2 ? padharamni.KaryakarName2 : "";
        padharamni.KaryakarCell2 = padharamni.KaryakarCell2 ? padharamni.KaryakarCell2 : "";
        this.setState({ editPadharamniDetails: padharamni, showEditModal: true });
    }

    handleClose(save = false) {
        if (save) {
            this.props.refreshList(this.state.padharamniList[0].ZoneName);
        }
        this.setState({ showEditModal: false, editPadharamniDetails: {} });
    }

    handleResetValues() {
        this.setState({
            editPadharamniDetails: {},
            registerDisableBtn: false,
            registerWarningText: "",
            registerShowWarning: false
        });
    }

    handleCopy(value) {
        navigator.clipboard.writeText(`${window.location.href}?karyakar=${value}`);
        this.setState({ displayCopyModal: true });
    }

    changeValue(value, changeItem) {
        let padharamniDetails = this.state.editPadharamniDetails;
        if (changeItem === 1) {
            padharamniDetails.KaryakarName1 = value;
        } else if (changeItem === 2) {
            padharamniDetails.KaryakarCell1 = value.substring(0,10);
        } else if (changeItem === 3) {
            padharamniDetails.KaryakarName2 = value;
        } else {
            padharamniDetails.KaryakarCell2 = value.substring(0,10);
        }
        this.setState({ editPadharamniDetails: padharamniDetails });
    }

    handleRegister() {
        // Check fields are filled
        let unfilledFields = this.state.editPadharamniDetails.KaryakarName1.length < 1 || this.state.editPadharamniDetails.KaryakarCell1.length < 1;
        if (unfilledFields === true) {
            this.setState({ registerWarningText: "Required fields are missing.", registerShowWarning: true });
            return;
        }

        // let url = `http://localhost:5004/api/padharamni/availabilities`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/availabilities` // Production
        let body = {
            id: this.state.editPadharamniDetails.Id,
            karyakarName1: this.state.editPadharamniDetails.KaryakarName1,
            karyakarCell1: this.state.editPadharamniDetails.KaryakarCell1,
            karyakarName2: this.state.editPadharamniDetails.KaryakarName2,
            karyakarCell2: this.state.editPadharamniDetails.KaryakarCell2
        }
        this.setState({ registerDisableBtn: true });
        axios.post(url, body).then((res) => {
            if (res.data.data.affectedRows > 0) {
                this.handleClose(true);
            }
            else {
                this.setState({ registerDisableBtn: false });
                this.setState({ registerWarningText: "An error occured while sending your information. Please try again.", registerShowWarning: true });
            }
        });
    }

    render() {
        return (
            <Accordion defaultActiveKey="karyakarTable">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="karyakarTable">
                        Padharamni Details
                        <span></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="karyakarTable">
                        <Card.Body>
                            <Table striped className="padharamniTable tableDesktop">
                                <thead>
                                    <tr>
                                        <th>Info</th>
                                        <th>Karyakar 1</th>
                                        <th>Karyakar 2</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.padharamniList.map((padharamni) => (
                                        <tr key={padharamni.Id}>
                                            <td>
                                                <b>Date:</b> {moment.utc(padharamni.Date).format('dddd').substring(0, 3) + ", " + moment.utc(padharamni.Date).format('LL')}<br />
                                                <b>Time:</b> {padharamni.Time}<br />
                                                <b>Sant Info:</b><br />
                                                {padharamni.SantName1}<br />
                                                {padharamni.SantName2}
                                            </td>
                                            <td>
                                                <b>Name:</b> {padharamni.KaryakarName1}<br />
                                                <b>Phone no:</b> <a href={`tel:${padharamni.KaryakarCell1}`}>{padharamni.KaryakarCell1}</a> {padharamni.KaryakarCell1.length > 1 && <Link45deg onClick={()=> this.handleCopy(padharamni.KaryakarCell1)} className="copyLink"></Link45deg>}
                                            </td>
                                            <td>
                                                <b>Name:</b> {padharamni.KaryakarName2}<br />
                                                <b>Phone no:</b> <a href={`tel:${padharamni.KaryakarCell2}`}>{padharamni.KaryakarCell2}</a> {padharamni.KaryakarCell2.length > 1 && <Link45deg onClick={()=> this.handleCopy(padharamni.KaryakarCell2)} className="copyLink"></Link45deg>}
                                            </td>
                                            <td><button onClick={() => this.editPadharamni(padharamni)}><img src={pencil_icon} alt="Edit padharamni details" /></button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Table striped className="padharamniTable tableMobile">
                                <thead>
                                    <tr>
                                        <th>Padharamni Info</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.padharamniList.map((padharamni) => (
                                        <tr key={padharamni.Id}>
                                            <td>
                                                <b>Date:</b> {moment.utc(padharamni.Date).format('dddd').substring(0, 3) + ", " + moment.utc(padharamni.Date).format('LL')}<br />
                                                <b>Time:</b> {padharamni.Time}<br />
                                                <b>Sant Info:</b><br />
                                                {padharamni.SantName1}<br />
                                                {padharamni.SantName2}<br />
                                                <br />
                                                <b>Karykar Information:</b><br/>
                                                <b>Name:</b> {padharamni.KaryakarName1}<br />
                                                <b>Phone no:</b> <a href={`tel:${padharamni.KaryakarCell1}`}>{padharamni.KaryakarCell1}</a> {padharamni.KaryakarCell1.length > 1 && <Link45deg onClick={()=> this.handleCopy(padharamni.KaryakarCell1)} className="copyLink"></Link45deg>}<br /> <br/>
                                                <b>Name:</b> {padharamni.KaryakarName2}<br />
                                                <b>Phone no:</b> <a href={`tel:${padharamni.KaryakarCell2}`}>{padharamni.KaryakarCell2}</a> {padharamni.KaryakarCell2.length > 1 && <Link45deg onClick={()=> this.handleCopy(padharamni.KaryakarCell2)} className="copyLink"></Link45deg>}<br />
                                            </td>
                                            <td><button onClick={() => this.editPadharamni(padharamni)}><img src={pencil_icon} alt="Edit padharamni details" /></button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Modal show={this.state.showEditModal} onHide={() => this.handleClose()} size="md" className="modalCont" scrollable={true}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div>
                            <p>
                                <b>Date:</b> {moment.utc(this.state.editPadharamniDetails.Date).format('dddd').substring(0, 3) + ", " + moment.utc(this.state.editPadharamniDetails.Date).format('LL')}<br />
                                <b>Time:</b> {this.state.editPadharamniDetails.Time}<br />
                                <b>Sant Info:</b><br />
                                {this.state.editPadharamniDetails.SantName1}<br />
                                {this.state.editPadharamniDetails.SantName2}
                            </p>
                            {this.state.registerShowWarning ? <Alert variant={"danger"}>{this.state.registerWarningText}</Alert> : ""}
                            <Form.Group className="mb-3">
                                <Form.Label>Karyakar 1 Name:<sup>*</sup></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.state.editPadharamniDetails.KaryakarName1}
                                    onChange={(event) => this.changeValue(event.target.value, 1)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Karyakar 1 Cell:<sup>*</sup></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.state.editPadharamniDetails.KaryakarCell1}
                                    onChange={(event) => this.changeValue(event.target.value, 2)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Karyakar 2 Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.state.editPadharamniDetails.KaryakarName2}
                                    onChange={(event) => this.changeValue(event.target.value, 3)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Karyakar 2 Cell:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.state.editPadharamniDetails.KaryakarCell2}
                                    onChange={(event) => this.changeValue(event.target.value, 4)}
                                />
                            </Form.Group>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                            Close
                        </Button>
                        <div>
                            <Button variant="primary" onClick={() => this.handleRegister()} disabled={this.state.registerDisableBtn}>
                                Submit
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal size="sm" className="copyLinkModal" show={this.state.displayCopyModal} onHide={() => this.setState({displayCopyModal: false})}>
                    <Alert className="" variant={"success"}><Clipboard2CheckFill className="copyLink"></Clipboard2CheckFill>Link Copied to clipboard!</Alert>
                </Modal>
            </Accordion>

        );
    }
}

export default withParams(PadharamniZoneList);
