// Vendor Files
import React from "react";
import { Form, Modal, Alert, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class RSVPStudentSabha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rsvpFirstName: "",
            rsvpLastName: "",
            rsvpEmail: "",
            rsvpPhone: "",
            rsvpAvailable: "",
            rsvpGender: "",
            rsvpUniversity: "",
            rsvpOther: "",
            rsvpAddress: "",
            rsvpShowFormWarning: false,
            rsvpWarningText: "",
            rsvpDisableRegisterBtn: false,
            rsvpShowSuccessMsg: false,
            rsvpSuccessText: "",
        };
    }

    componentDidMount() {
        if (this.props.formData.displayName === "Hindi Sabha") {
            this.setState({ rsvpUniversity: "" });
        }
    }

    handleClose() {
        this.props.hideForm();
    }

    handleRSVPRegister() {
        // Check fields are filled
        let unfilledFields = this.state.rsvpFirstName === "" || this.state.rsvpLastName === "" || this.state.rsvpEmail === "" || this.state.rsvpPhone === "" || this.state.rsvpAvailable === "" || this.state.rsvpGender === "" || this.state.rsvpAddress === "";
        if (unfilledFields === true) {
            this.setState({ rsvpWarningText: "All fields are required.", rsvpShowFormWarning: true });
            return;
        }

        if (this.props.formData.displayName === "Hindi Sabha" && this.state.rsvpAvailable === "Yes, I'll be there"  && this.state.rsvpUniversity === "") {
            this.setState({ rsvpWarningText: "All fields are required.", rsvpShowFormWarning: true });
            return;
        }

        if (this.props.formData.displayName === "Student Sabha" && this.state.rsvpUniversity === "") {
            this.setState({ rsvpWarningText: "All fields are required.", rsvpShowFormWarning: true });
            return;
        }

        if (this.state.rsvpUniversity === "Other" && this.state.rsvpOther === "") {
            this.setState({ rsvpWarningText: "All fields are required.", rsvpShowFormWarning: true });
            return;
        }

        // Check email is in correct format
        if (!this.validateEmail(this.state.rsvpEmail)) {
            this.setState({ rsvpWarningText: "Please enter a valid email.", rsvpShowFormWarning: true });
            return;
        }

        // let url = "http://localhost:5004/api/student-hindi-sabha/rsvp/" // Dev testing
        let url = "https://mw-chicago-mandir-backend.web.app/api/student-hindi-sabha/rsvp/" // Production
        let body = {
            event: this.props.formData.eventId,
            eventDisplayName: this.props.formData.displayName,
            firstName: this.state.rsvpFirstName,
            lastName: this.state.rsvpLastName,
            email: this.state.rsvpEmail,
            phone: this.state.rsvpPhone,
            availability: this.state.rsvpAvailable,
            gender: this.state.rsvpGender,
            university: this.state.rsvpUniversity,
            other: this.state.rsvpUniversity !== "Other" ? "" : this.state.rsvpOther,
            address: this.state.rsvpAddress,
            eventAddress: this.props.formData.location,
            eventDay: this.props.formData.eventDays[0]
        }
        this.setState({ rsvpDisableRegisterBtn: true });
        axios.post(url, body).then((res) => {
            if (res.data.data.affectedRows > 0) {
                this.setState({ 
                    rsvpDisableRegisterBtn: false, 
                    rsvpShowSuccessMsg: true, 
                    rsvpSuccessText: this.state.rsvpAvailable === "Yes, I'll be there" ? "We have successfully registered you for the event, please check your email for more details." : "We are sorry that you won't be able to attend. See you next time!", 
                    rsvpShowFormWarning: false });
                this.handleResetFormFields();
            }
            else {
                this.setState({ rsvpDisableRegisterBtn: false });
                this.setState({ rsvpWarningText: "An error occured while sending your information. Please try again.", rsvpShowFormWarning: true });
            }
        });
    }

    handleResetFormFields() {
        this.setState({
            rsvpFirstName: "",
            rsvplastName: "",
            rsvpEmail: "",
            rsvpPhone: "",
            rsvpAvailable: "",
            rsvpGender: "",
            rsvpUniversity: "",
            rsvpAddress: "",
            rsvpOther: ""
        });
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    render() {
        return (
            <Modal show={true} onHide={() => this.handleClose()} size="lg" className="modalCont" scrollable={true}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="modalHeader">
                        <h2>{this.props.formData.displayName}</h2>
                        <p>{this.props.formData.description}</p>
                        <p>
                            <b>Date:</b> {this.props.formData.date} <br/>
                            <b>Time:</b> {this.props.formData.time} <br/>
                            <b>Location:</b> {this.props.formData.location}
                        </p>
                    </div>

                    {this.state.rsvpShowFormWarning ? <Alert variant={"danger"}>{this.state.rsvpWarningText}</Alert> : ""}
                    {this.state.rsvpShowSuccessMsg ? <Alert variant={"success"}>{this.state.rsvpSuccessText}</Alert> : ""}
                    {!this.state.rsvpShowSuccessMsg &&
                        <Form autoComplete="disabled">
                            <Container>
                                <Row className="nameCont">
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Firstname:<sup>*</sup></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your first name"
                                                name="fname"
                                                value={this.state.rsvpFirstName}
                                                onChange={(event) => this.setState({ rsvpFirstName: event.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Lastname:<sup>*</sup></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your last name"
                                                name="lname"
                                                value={this.state.rsvpLastName}
                                                onChange={(event) => this.setState({ rsvpLastName: event.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Container>
                            <Form.Group className="mb-3">
                                <Form.Label>Email:<sup>*</sup></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={this.state.rsvpEmail}
                                    onChange={(event) => this.setState({ rsvpEmail: event.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Cell Phone:<sup>*</sup></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter your phone number"
                                    name="phone"
                                    value={this.state.rsvpPhone}
                                    onChange={(event) => this.setState({ rsvpPhone: event.target.value.substring(0,10) })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Can you make it?<sup>*</sup></Form.Label>
                                <Form.Control as="select" value={this.state.rsvpAvailable} onChange={(event) => this.setState({ rsvpAvailable: event.target.value })}> 
                                    <option className="hideOption"></option>
                                    <option disabled value=""></option>
                                    <option>Yes, I'll be there</option>
                                    <option>Sorry, can't make it</option>
                                </Form.Control>
                            </Form.Group>
                            {this.props.formData.displayName === "Hindi Sabha" && this.state.rsvpAvailable === "Yes, I'll be there" &&
                                <Form.Group className="mb-3">
                                    <Form.Label>No. of people attending (including you):<sup>*</sup></Form.Label>
                                    <Form.Control
                                        type="number"
                                        autoComplete="off"
                                        name="other"
                                        placeholder="Enter number of people attending"
                                        value={this.state.rsvpUniversity}
                                        onChange={(event) => this.setState({ rsvpUniversity: event.target.value.substring(0,2) })}
                                    />
                                </Form.Group>
                            }
                            <Form.Group className="mb-3">
                                <Form.Label>Gender:<sup>*</sup></Form.Label>
                                <Form.Control as="select" value={this.state.rsvpGender} onChange={(event) => this.setState({ rsvpGender: event.target.value })}> 
                                    <option className="hideOption"></option>
                                    <option disabled value=""></option>
                                    <option>Female</option>
                                    <option>Male</option>
                                </Form.Control>
                            </Form.Group>
                            {this.props.formData.displayName === "Student Sabha" && 
                                <Form.Group className="mb-3">
                                    <Form.Label>University:<sup>*</sup></Form.Label>
                                    <Form.Control as="select" value={this.state.rsvpUniversity} onChange={(event) => this.setState({ rsvpUniversity: event.target.value })}>
                                        <option className="hideOption"></option>
                                        <option disabled>Pick from below:</option>
                                        <option>IIT</option>
                                        <option>UIC</option>
                                        <option>DePaul</option>
                                        <option>Roosevelt University</option>
                                        <option>Eastwest University</option>
                                        <option>University of Chicago</option>
                                        <option>Governors State University</option>
                                        <option>Other</option>
                                    </Form.Control>
                                </Form.Group>
                            }
                            {this.state.rsvpUniversity === "Other" && 
                                <Form.Group className="mb-3">
                                    <Form.Label>Other:<sup>*</sup></Form.Label>
                                    <Form.Control
                                        name="other"
                                        autoComplete="off"
                                        placeholder="Enter your university"
                                        value={this.state.rsvpOther}
                                        onChange={(event) => this.setState({ rsvpOther: event.target.value })}
                                    />
                                </Form.Group>
                            }
                            <Form.Group className="mb-3">
                                <Form.Label>Address:<sup>*</sup></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your address"
                                    name="address"
                                    value={this.state.rsvpAddress}
                                    onChange={(event) => this.setState({ rsvpAddress: event.target.value })}
                                />
                            </Form.Group>
                        </Form>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                    {!this.state.rsvpShowSuccessMsg &&
                        <div>
                            <Button variant="primary" onClick={() => this.handleRSVPRegister()} disabled={this.state.rsvpDisableRegisterBtn}>
                                Register Now
                            </Button>
                        </div>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withParams(RSVPStudentSabha);