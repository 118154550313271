// Vendor Files
import React from "react";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import * as moment from 'moment-timezone';
import { useParams } from "react-router";
import _ from "lodash";

// Custom Components
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import MainForm from "../../components/Forms/mainForm";

// Events List
import { eventsList } from './eventsList';

// Image Files
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventsList: [],
      eventsStructuredData: [],
      formData: {},
      showForm: false
    };
    this.hideForm = this.hideForm.bind(this);
  }

  componentDidMount() {
    this.setState({eventsList: eventsList}, () => {
      this.checkHiddenEvents();
    });
    this.createStructuredData();
  }

  checkHiddenEvents() {
    let { event_name } = this.props.params;
    if (event_name) {
      let event = _.filter(this.state.eventsList, (event) => {return event.id === event_name})[0]
      if (event && event.active && event.needRsvp) {
          this.setState({formData: event.formData, showForm: true});
      }
    }
  }

  checkEventEndDate(eventEndTime) {
    let currTime = moment().tz("America/Chicago").format(); //Get chicago time
    let eventTime = moment(new Date(eventEndTime)).format(); //Get event time
    return currTime < eventTime; //check if event has ended
  }

  createStructuredData() {
    let events = eventsList;
    let structuredData = [];
    events.forEach(event => {
      if (!event.hideList) {
        let eventData = {
          "@context": "https://schema.org",
          "@type": "Event",
          "name": event.displayName,
          "startDate": event.eventStartDateTime,
          "endDate": event.eventEndDateTime,
          "location": {
            "@type": "Place",
            "name": "BAPS Shri Swaminarayan Mandir",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1851 S IL Route 59",
              "addressLocality": "Bartlett",
              "postalCode": "60103",
              "addressRegion": "IL",
              "addressCountry": "US"
            }
          },
          "image": [
            event.imageURL,
          ],
          "description": event.description
        };
        structuredData.push(eventData);
      }
    });
    this.setState({eventsStructuredData: structuredData});
  }

  showForm(formData) {
    this.setState({formData: formData, showForm: true});
  }

  hideForm() {
    this.setState({formData: {}, showForm: false});
  }

  render() {
    return (
      <div>
        <Header metaPage="events"></Header>

        <div className="events sectionCont">
          <h1 className="title">Upcoming Events</h1>
          <p className="sub-title"><i>BAPS Shri Swaminarayan Mandir, Chicago, IL cordially invites you, your family and friends to join us in celebrating these joyous occasions.</i></p>
          <br /><br />
          <Container className="eventList eventCont">
            <Row>
              {this.state.eventsList.map((event) => (
                this.checkEventEndDate(event.eventEndDateTime) && !event.hideList && event.active ? 
                  <Col md={12} id={event.id}>
                    <Card>
                      <Card.Img variant="top" alt={event.displayName} src={require(`../../assets/images/events/${event.image}`).default} />
                      <div className="dateCont">{event.date.split(' ')[1].substring(0,3)}<span>{event.date.split(' ')[2].slice(0,-2)}</span></div>
                      <Card.Body>
                        <h3>{event.displayName}</h3>
                        <p>
                          <strong>Date:</strong> {event.date}<br />
                          <strong>Time:</strong> {event.time}
                        </p>
                        <p>{event.description}</p>
                        {event.actionLink && <Button target={event.actionLink.newTab && "_blank"} href={event.actionLink.url}>{event.actionLink.text}</Button>}
                        {event.needRsvp && <Button onClick={() => this.showForm(event.formData)}>{event.needRsvp.text}</Button>}
                      </Card.Body>
                    </Card>
                  </Col>
                : ""
              ))}
            </Row>
            {this.state.showForm && <MainForm formName={this.state.formData.formName} formData={this.state.formData} hideForm={this.hideForm}></MainForm>}
          </Container>
        </div>

        <Footer></Footer>

        <script type='application/ld+json'>
          {JSON.stringify(this.state.eventsStructuredData)}
        </script>
      </div>
    );
  }
}

export default withParams(Events);
