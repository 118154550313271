// Vendor Files
import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';

// Image Files
import psm_msm_white from "../assets/logos/psm-logo-msm-WHITE.png";
import insta_logo from "../assets/logos/instagram.png";
import telegram_logo from "../assets/logos/telegram.png";
import whatsapp_logo from "../assets/logos/whatsapp.png";
import facebook_logo from "../assets/logos/facebook.png";
import weblink_logo from "../assets/logos/weblink.png";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <div className="divider-line"></div>
                <div className="sectionCont">
                    <Container>
                        <h2>Stay Connected</h2>
                        <div className={"gridCont"}>
                            <Row>
                                <Col md={6} className="gridCol">
                                    <h3>Global Website</h3>
                                    <p className="announcement-times">
                                        Visit our global website for latest updates on His Holiness Mahant
                                        Swami Maharaj's vicharan, activities, blessings, and sanstha related&nbsp;information.
                                    </p>
                                    <Button target="_blank" href="https://www.baps.org">Learn More</Button>
                                </Col>
                                <Col md={6} className="gridCol">
                                    <h3>Connect with Us</h3>
                                    <p className="announcement-times">
                                        Follow us on social media and stay informed about upcoming events, latest news and inspiring stories.
                                        Join our communication channels to stay connected.
                                    </p>
                                    <div
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="social-icons-container"
                                    >
                                        <a
                                            style={{ margin: "auto", display: "flex" }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.facebook.com/bapschicago"
                                        >
                                            <img
                                                className="footer-social-icon"
                                                srcSet={facebook_logo + " 400w"}
                                                sizes="300px"
                                                src={facebook_logo}
                                                alt="BAPS Chicago - Facebook"
                                            />
                                        </a>
                                        <a
                                            style={{ margin: "auto", display: "flex" }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.instagram.com/baps.chicago/"
                                        >
                                            <img
                                                className="footer-social-icon"
                                                srcSet={insta_logo + " 400w"}
                                                sizes="300px"
                                                src={insta_logo}
                                                alt="BAPS Chicago Instagram"
                                            />
                                        </a>
                                        <a
                                            style={{ margin: "auto", display: "flex" }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="http://tinyurl.com/BAPSChicago"
                                        >
                                            <img
                                                className="footer-social-icon facebook"
                                                srcSet={telegram_logo + " 400w"}
                                                sizes="300px"
                                                src={telegram_logo}
                                                alt="BAPS Chicago Telegram"
                                            />
                                        </a>
                                        <a
                                            style={{ margin: "auto", display: "flex" }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.baps.org/Global-Network/North-America/Chicago.aspx"
                                        >
                                            <img
                                                className="footer-social-icon"
                                                srcSet={weblink_logo + " 400w"}
                                                sizes="300px"
                                                src={weblink_logo}
                                                alt="BAPS Chicago Site"
                                            />
                                        </a>
                                        {/* facebook: https://www.facebook.com/bapschicagoInstagram: https://www.instagram.com/baps.chicago/Telegram: http://tinyurl.com/BAPSChicagoLink: https://www.baps.org/Global-Network/North-America/Chicago.aspx */}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a
                                        href="whatsapp://send?text=BAPS Live Webcast Midwest: https://chicagomandir.org"
                                        data-action="share/whatsapp/share"
                                        style={{
                                            color: "#136cce",
                                            textDecoration: "none",
                                            display: "inline-block"
                                        }}
                                    >
                                        <div style={{ display: "flex" }}>
                                            <h3 style={{ color: "#1b1e2f" }}>Share via</h3>
                                            <img
                                                className="whatsapp-app-icon"
                                                srcSet={whatsapp_logo + " 1280w"}
                                                sizes="300px"
                                                src={whatsapp_logo}
                                                alt="Share via WhatsApp"
                                            />
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <footer>

                    <div className="footer-content-container">
                        <img className="footer-image" src={psm_msm_white} alt="BAPS" />
                        <div className="footer-charity-text-container">
                            <p>&copy; {(new Date().getFullYear())} BAPS Swaminarayan Sanstha. All Rights Reserved.</p>
                            <p>
                                1851 S IL Route 59 Pramukh Swami Rd Bartlett, IL 60103-3008 USA
                            </p>
                        </div>
                    </div>
                </footer>
            </div>

        );
    }
}