// Vendor Files
import React from "react";
import { Container, Row, Col, Button, Card, Form, Modal, Alert } from 'react-bootstrap';
import axios from 'axios';

// Custom Components
import Header from "../components/Header"
import Footer from "../components/Footer"

// Image Files
import diwali_banner from "../assets/images/diwali_banner.png"
import kdc_image from "../assets/images/kdc.jpg"
import diwali_image from "../assets/images/diwali.jpg"
import fireworks_image from "../assets/images/fireworks.jpg"
import annakut_image from "../assets/images/annakut.jpg"
import exhibition_image from "../assets/images/exhibition.jpg"
import parking_image from "../assets/images/parking.PNG"
import samaiyo_image from "../assets/images/diwali_samaiyo.jpg"
import pujan_image from "../assets/images/pujan.jpg"
// import hanuman_pujan from "../assets/images/hanuman_pujan.jpg"

export default class Diwali extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRegisterModal: false,
      rsvpFirstName: "",
      rsvpLastName: "",
      rsvpEmail: "",
      rsvpPhone: "",
      rsvpPreferredTime: "",
      rsvpAttendees: "",
      rsvpWarningText: "",
      rsvpDisableRegisterBtn: false,
      rsvpShowSuccessMsg: false,
      rsvpShowFormWarning: false
    };
  }

  componentDidMount() {
    if (window.location.href.indexOf("#chopda-pujan") > -1) {
      this.handleOpen();
    }
  }

  handleClose() {
    this.setState({
      showRegisterModal: false,

      rsvpDisableRegisterBtn: false,
      rsvpShowSuccessMsg: false
    });
  }
  handleOpen() {
    this.setState({
      showRegisterModal: true
    });
    this.handleResetFormFields();
  }

  handleResetFormFields() {
    this.setState({
      rsvpFirstName: "",
      rsvpLastName: "",
      rsvpEmail: "",
      rsvpPhone: "",
      rsvpPreferredTime: "",
      rsvpAttendees: "",
      rsvpWarningText: ""
    });
  }

  handleRSVPRegister() {
    // Check fields are filled
    let unfilledFields = this.state.rsvpPreferredTime === "" || this.state.rsvpAttendees === "" || this.state.rsvpEmail === "" || this.state.rsvpFirstName === "" || this.state.rsvpLastName === "" || this.state.rsvpPhone === "";
    if (unfilledFields === true) {
      this.setState({ rsvpWarningText: "Please fill out the required fields.", rsvpShowFormWarning: true });
      return;
    }

    // Check email is in correct format
    if (!this.validateEmail(this.state.rsvpEmail)) {
      this.setState({ rsvpWarningText: "Please enter a valid email.", rsvpShowFormWarning: true });
      return;
    }

    // let url = "http://localhost:5004/api/rsvp/" + this.state.selEvent.name // Dev testing
    let url = "https://mw-chicago-mandir-backend.web.app/api/rsvp/Diwali Chopda Pujan"; // Production
    let body = {
      event: "diwali-chopda-pujan",
      eventDisplayName: "Diwali Chopda Pujan",
      prefferedDay: this.state.rsvpPreferredTime,
      attendees: this.state.rsvpAttendees,
      email: this.state.rsvpEmail,
      firstName: this.state.rsvpFirstName,
      lastName: this.state.rsvpLastName,
      phone: this.state.rsvpPhone,
      zipcode: "",
      address: "",
      source: "",
      eventAddress: "BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103"
    };
    this.setState({ rsvpDisableRegisterBtn: true });
    axios.post(url, body).then((res) => {
      if (res.data.data.affectedRows > 0) {
        this.setState({ rsvpDisableRegisterBtn: false, rsvpShowSuccessMsg: true, rsvpShowFormWarning: false });
        this.handleResetFormFields();
      }
      else {
        this.setState({ rsvpDisableRegisterBtn: false });
        this.setState({ rsvpWarningText: "An error occured while sending your information. Please try again.", rsvpShowFormWarning: true });
      }
    });
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  render() {
    return (
      <div>
        
        <Header metaPage="diwali"></Header>
        <Container>
          <div className="bannerCont sectionCont">
            <Alert variant={"success"}>Thank you for joining us at our event! Your time and attention are truly appreciated. To learn more about our upcoming events, <a href="/events">click here</a>.</Alert>
            <br/> <br/>


            <img src={diwali_banner} alt="Diwali and New Years Celebrations Announcements" />
            <p>Diwali is a multi-faith and multi-day festival which is joyously celebrated across BAPS Mandirs in North America. Diwali also known as the ‘festival of lights’ was observed by devotees at mandirs and at home, embracing traditions of reflection, appreciation, positivity, and the strengthening of familial, communal, and spiritual bonds.</p>
          </div>
        </Container>
          <div className="divider-line"></div>

          <div className="events sectionCont">
            <h2 className="title">Event Details</h2>
            <p className="sub-title"><i>BAPS Shri Swaminarayan Mandir, Chicago, IL cordially invites you, your family and friends to celebrate Diwali & the Hindu New Year!</i></p>
            <br/>
            <Container className="eventCont eventTimeline">
              <Row className="event-wrap">
                <ul>
                  <li className="event-single clearfix list-kdc">
                    <Row>
                      <Col md={6} sm={6}>
                        <img className="event-image" src={kdc_image} alt="BAPS" />
                      </Col>
                      <Col md={6} sm={6}>
                        <div className="event-content">
                          <h3>Kids' Diwali Celebration</h3>
                          <p>
                            <strong>Date:</strong> Saturday, October 28th<br />
                            <strong>Time:</strong> 10:00 AM - 7:00 PM<br />
                            <strong>Fee (Per Child):</strong> $25 Online | $30 Walk-In
                          </p>
                          <p>Growing up in a diverse continent like North America, children experience an array of cultures, languages, values, and beliefs. Amidst this, BAPS strives to preserve Hindu culture and Indian traditions abroad. Through the annual Kids Diwali Celebration, families will have the opportunity to experience culture through games, food and fun!</p>
                          <Button target="_blank" href="https://bapsbky.typeform.com/chicagokdc2023">Register your family</Button>
                        </div>
                      </Col>
                    </Row>
                  </li>
                  <li className="event-single clearfix list-fireworks">
                    <Row>
                      <Col md={6} sm={6}>
                        <div className="event-content">
                          <h3>Diwali Fireworks Show</h3>
                          <p>
                            <strong>Date:</strong> Saturday, November 11th<br />
                            <strong>Time:</strong> 8:00 PM - 8:30 PM
                          </p>
                          <p>The Diwali Fireworks and Sound Show is a unique fusion of light, music and a cultural program. Please ensure to arrive early to have mandir darshan and proceed to the front of the Mandir to view the fireworks show.</p>
                        </div>
                      </Col>
                      <Col md={6} sm={6}>
                        <img src={fireworks_image} alt="BAPS" />
                      </Col>
                    </Row>
                  </li>
                  <li className="event-single clearfix list-diwali">
                    <Row>
                      <Col md={6} sm={6}>
                        <img src={diwali_image} alt="BAPS" />
                      </Col>
                      <Col md={6} sm={6}>
                        <div className="event-content">
                          <h3>Diwali and Chopda Pujan</h3>
                          <p>
                            <strong>Date:</strong> Sunday, November 12th<br />
                            <strong>Time:</strong> 4:45 PM - 7:15 PM
                          </p>
                          <p>One of the most auspicious days in the Hindu calendar will be celebrated with great devotion and splendor. A ceremony, known as Chopda Pujan, will take place in the evening. Hundreds of new account books – in a variety of paper and electronic forms – are sanctified through the showering of flowers, petals and rice grains amid the chanting of Vedic hymns and prayers.</p>
                          {/* <Button target="_blank" onClick={() => { this.handleOpen() }}>Register for Chopda Pujan</Button> */}
                        </div>
                      </Col>
                    </Row>
                  </li>
                  <li className="event-single clearfix list-annakut">
                    <Row>
                      <Col md={6} sm={6}>
                        <div className="event-content">
                          <h3>Family Annakut Darshan</h3>
                          <p>
                            <strong>Date:</strong> Monday, November 13th<br />
                            <strong>Time:</strong> 12:00 PM - 8:00 PM
                          </p>
                          <p>The day following Diwali is the beginning of the Hindu New Year. The Hindu New Year is traditionally celebrated with the Annakut – literally meaning, a mountain of food. Every year, thousands of vegetarian delicacies are offered in devotional gratitude for the past year and to seek blessings for the New Year ahead. We cordially invite you for darshan of the Annakut with family and friends.</p>
                          <Button target="_blank" href="https://donate.na.baps.org/sponsor/c?m=102&qr=1&cid=bTVlQy00MjUtMTAyaDd1OTJs&lk89dsk8skH8gdWff5=RU5ET1dNRU5U">Sponsor for this Occasion</Button>
                        </div>
                      </Col>
                      <Col md={6} sm={6}>
                        <img src={annakut_image} alt="BAPS" />
                      </Col>
                    </Row>
                  </li>
                  <li className="event-single clearfix list-exhibition">
                    <Row>
                      <Col md={6} sm={6}>
                        <img className="event-image" src={exhibition_image} alt="BAPS" />
                      </Col>
                      <Col md={6} sm={6}>
                        <div className="event-content">
                          <h3>‘Mandirs Inspire’ - Annual Community Exhibition</h3>
                          <p>
                            <strong>Date:</strong> Monday November 13th<br />
                            <strong>Time:</strong> 12:00 PM - 8:00 PM
                          </p>
                          <p>Each year, the Mandir celebrates the New Year with a themed exhibition created by the youth of BAPS. This year, learn how mandirs - Hindu houses of worship - inspire positive thoughts, values, service and culture.<br /><br />
                          प्रत्येक वर्ष, बी.ए.पी.एस. के युवा एक प्रदर्शन बनाते हैं। इस वर्ष, जानें कि मंदिर कैसे समाज में सद्विचार, संस्कार, सेवा और संस्कृति की प्रेरणा देते हैं।</p>
                        </div>
                      </Col>
                    </Row>
                  </li>
                  <li className="event-single clearfix list-samaiyo">
                    <Row>
                      <Col md={6} sm={6}>
                        <div className="event-content">
                          <h3>Diwali Samaiyo</h3>
                          <p>
                            <strong>Date:</strong> November 18th and 19th<br />
                            <strong>Time:</strong> 4:00 PM - 6:00 PM
                          </p>
                          <p>Celebrate the first Gujarati satsang sabha (weekly spiritual assembly) of the Hindu New Year with spiritual discourses, inspiring videos, and kirtan-bhakti.</p>
                          <Button target="_blank" href="https://donate.na.baps.org/sponsor/c?m=102&qr=1&cid=bTVlQy00MjUtMTAyaDd1OTJs&lk89dsk8skH8gdWff5=RU5ET1dNRU5U">Sponsor this occasion</Button>
                        </div>
                      </Col>
                      <Col md={6} sm={6}>
                        <img src={samaiyo_image} alt="BAPS" />
                      </Col>
                    </Row>
                  </li>
                </ul>
              </Row>
            </Container>
            <Container className="eventCont eventStack">
              <Row>
                <Col md={6}>
                  <Card>
                    <Card.Img variant="top" src={kdc_image} />
                    <Card.Body>
                      <h3>Kids' Diwali Celebration</h3>
                      <p>
                        <strong>Date:</strong> Saturday, October 28th<br />
                        <strong>Time:</strong> 10:00 AM - 7:00 PM<br />
                        <strong>Fee (Per Child):</strong> $25 Online | $30 Walk-In
                      </p>
                      <p>Growing up in a diverse continent like North America, children experience an array of cultures, languages, values, and beliefs. Amidst this, BAPS strives to preserve Hindu culture and Indian traditions abroad. Through the annual Kids Diwali Celebration, families will have the opportunity to experience culture through games, food and fun!</p>
                      <Button target="_blank" href="https://bapsbky.typeform.com/chicagokdc2023">Register your Family</Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <Card.Img variant="top" src={fireworks_image} />
                    <Card.Body>
                      <h3>Diwali Fireworks Show</h3>
                      <p>
                        <strong>Date:</strong> Saturday, November 11th<br />
                        <strong>Time:</strong> 8:00 PM - 8:30 PM
                      </p>
                      <p>The Diwali Fireworks and Sound Show is a unique fusion of light, music and a cultural program. Please ensure to arrive early to have mandir darshan and proceed to the front of the Mandir to view the fireworks show.</p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <Card.Img variant="top" src={diwali_image} />
                    <Card.Body>
                      <h3>Diwali and Chopda Pujan</h3>
                      <p>
                        <strong>Date:</strong> Sunday, November 12th<br />
                        <strong>Time:</strong> 4:45 PM - 7:15 PM
                      </p>
                      <p>One of the most auspicious days in the Hindu calendar will be celebrated with great devotion and splendor. A ceremony, known as Chopda Pujan, will take place in the evening. Hundreds of new account books – in a variety of paper and electronic forms – are sanctified through the showering of flowers, petals and rice grains amid the chanting of Vedic hymns and prayers.</p>
                      {/* <Button target="_blank" onClick={() => { this.handleOpen() }}>Register for Chopda Pujan</Button> */}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <Card.Img variant="top" src={annakut_image} />
                    <Card.Body>
                      <h3>Family Annakut Darshan</h3>
                      <p>
                        <strong>Date:</strong> Monday, November 13th<br />
                        <strong>Time:</strong> 12:00 PM - 8:00 PM
                      </p>
                      <p>The day following Diwali is the beginning of the Hindu New Year. The Hindu New Year is traditionally celebrated with the Annakut – literally meaning, a mountain of food. Every year, thousands of vegetarian delicacies are offered in devotional gratitude for the past year and to seek blessings for the New Year ahead. We cordially invite you for darshan of the Annakut with family and friends.</p>
                      <Button target="_blank" href="https://donate.na.baps.org/sponsor/c?m=102&qr=1&cid=bTVlQy00MjUtMTAyaDd1OTJs&lk89dsk8skH8gdWff5=RU5ET1dNRU5U">Sponsor For This Occasion</Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <Card.Img variant="top" src={exhibition_image} />
                    <Card.Body>
                      <h3>‘Mandirs Inspire’ - Annual Community Exhibition</h3>
                      <p>
                        <strong>Date:</strong> Monday November 13th<br />
                        <strong>Time:</strong> 12:00 PM - 8:00 PM
                      </p>
                      <p>Each year, the Mandir celebrates the New Year with a themed exhibition created by the youth of BAPS. This year, learn how mandirs - Hindu houses of worship - inspire positive thoughts, values, service and culture.<br /><br />
                        प्रत्येक वर्ष, बी.ए.पी.एस. के युवा एक प्रदर्शन बनाते हैं। इस वर्ष, जानें कि मंदिर कैसे समाज में सद्विचार, संस्कार, सेवा और संस्कृति की प्रेरणा देते हैं।
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <Card.Img variant="top" src={samaiyo_image} />
                    <Card.Body>
                      <h3>Diwali Samaiyo</h3>
                      <p>
                        <strong>Date:</strong> November 18th and 19th<br />
                        <strong>Time:</strong> 4:00 PM - 6:00 PM
                      </p>
                      <p>Celebrate the first Gujarati satsang sabha (weekly spiritual assembly) of the Hindu New Year with spiritual discourses, inspiring videos, and kirtan-bhakti.</p>
                      <Button target="_blank" href="https://donate.na.baps.org/sponsor/c?m=102&qr=1&cid=bTVlQy00MjUtMTAyaDd1OTJs&lk89dsk8skH8gdWff5=RU5ET1dNRU5U">Sponsor For This Occasion</Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </Container>
          </div>

          <div className="divider-line"></div>

          <div className="sectionCont">
            <h2>Directions & Parking Information</h2>
            <Container className={"gridCont"}>
              <Row>
                <Col md={12} className="gridCol parkingCont pujanCont">
                  <img className="" src={parking_image} alt="BAPS" />
                  <p>Click below to get directions to the Mandir.</p>
                  <Button target="_blank" href="https://www.baps.org/Global-Network/North-America/Chicago/Visitor-Info.aspx">Visitor Information</Button>
                  <Button target="_blank" href="https://goo.gl/maps/ib2j4yq5ET41QoAJ8">Directions to the Mandir</Button>
                  <p>
                    See the accompanying map for parking on-campus on Saturday, November 11th and Monday, November 13th.
                  </p>
                  <p>
                    On Saturday, November 11th (Fireworks program) all vehicles arriving after 6:30 pm will be directed to offsite parking at Bartlett High School. Shuttle service will be provided.
                    On Monday, November 13th (New Years Day) all vehicles arriving after 5:30 pm will be directed to offsite parking at Bartlett High School. Shuttle service will be provided.
                  </p>
                  <p>
                    Volunteers will assist you in finding parking space. Please adhere to their directions. <br />
                    {/* If parking is not available on-campus, overflow parking will be available offsite at Bartlett High School with shuttle services to the mandir. <br /> */}
                    When possible, carpool or drive together as a family to accommodate for the large crowds.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="divider-line"></div>

          <div className="sectionCont">
            <h2>Dhan Teras Pujan</h2>
            <Container className={"gridCont"}>
              <Row>
                <Col md={12} className="gridCol parkingCont pujanCont">
                  {/* <h3>Dhan Teras Pujan</h3> */}
                  {/* <br /> */}
                  <img className="" src={pujan_image} alt="BAPS" />
                  <p>
                    On Friday, November 10th, devotees pray to the Goddess Laxmiji for ethical economic prosperity and success in their careers. Lakshmi Pujan is a family event to be performed at-home as per one's feasibility.<br /><br />
                    Participate by following along with the video during one of the auspicious times below.
                  </p>
                  <p>
                    Morning: 6:34 AM - 10:19 AM <br />
                    Afternoon: 11:34 PM - 12:49 PM <br />
                    Evening: 8:05 PM - 9:50 PM
                  </p>
                  <Button target="_blank" href="https://youtu.be/CzDEqJ6fMGE">Lakshmi Pujan Video</Button>
                  <Button target="_blank" href="https://baps.sl/diwali2023">Preparation Items</Button>
                </Col>
                {/* <Col md={6} className="gridCol parkingCont pujanCont">
                  <h3>Hanuman Pujan</h3>
                  <br />
                  <img className="" src={hanuman_pujan} alt="BAPS" />
                  <p>
                    On Saturday, November 11th, devotees pray to Shri Hanumanji for strength and knowledge in the upcoming year. Hanuman Pujan is a family event to be performed at-home as per one's feasibility.<br /><br />
                    Participate by following along with the video during one of the auspicious times below.
                  </p>
                  <p>
                    Morning: 6:51 AM - 11:00 AM <br />
                    Afternoon: 12:00 PM - 1:46 PM <br />
                    Evening: 5:35 PM - 6:46 PM
                  </p>
                  <Button target="_blank" href="https://youtu.be/GKT9p7Vdaq0?t=1716">Hanuman Pujan Video</Button>
                </Col> */}
              </Row>
            </Container>
          </div>

          <div className="divider-line"></div>

          <div className="sectionCont">
            <h2>Share this event!</h2>
            <Container className={"gridCont"}>
              <Row>
                <Col md={6} className="">
                  <div className="iframeCont">
                    <iframe title="Diwali Promo Video" src="https://www.instagram.com/p/Cy1B2-kqEPn/embed/" allowtransparency="true" frameborder="0" scrolling="no"></iframe>
                  </div>
                </Col>
                <Col md={6} className="gridCol centerCont">
                  <div>
                    <p>Share the invitation video on Instagram by following and tagging us and including the hashtags:<br />#BAPSChicago #Diwali2023 #BAPSDiwali #MandirsInspire</p>
                    <p>Join our Telegram channel to stay up to date with the latest updates, emergency announcements and details on upcoming events.</p>
                    <Button target="_blank" href="https://www.tinyurl.com/BAPSChicago">Join Telegram Group</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

        <Footer></Footer>

        <Modal show={this.state.showRegisterModal} onHide={() => this.handleClose()} size="lg" className="modalCont" scrollable={true}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="modalHeader">
              <h2>Diwali Chopda Pujan</h2>
              <p>
                Fill out the form below to register for the event. We look forward to seeing you.
              </p>
            </div>
            {this.state.rsvpShowFormWarning ? <Alert variant={"danger"}>{this.state.rsvpWarningText}</Alert> : ""}
            {this.state.rsvpShowSuccessMsg ? <Alert variant={"success"}>We have successfully registered you for the event, please check your email for more details.</Alert> : ""}
            {!this.state.rsvpShowSuccessMsg ?
              <Form autoComplete="disabled">
                <Container>
                  <Row className="nameCont">
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Firstname:<sup>*</sup></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your full name"
                          value={this.state.rsvpFirstName}
                          onChange={(event) => this.setState({ rsvpFirstName: event.target.value })}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Lastname:<sup>*</sup></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your full name"
                          value={this.state.rsvpLastName}
                          onChange={(event) => this.setState({ rsvpLastName: event.target.value })}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number:<sup>*</sup></Form.Label>
                  <Form.Control
                    type="number"
                    pattern="[0-9]*"
                    placeholder="Enter your phone number"
                    value={this.state.rsvpPhone}
                    onChange={(event) => this.setState({ rsvpPhone: event.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email:<sup>*</sup></Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={this.state.rsvpEmail}
                    onChange={(event) => this.setState({ rsvpEmail: event.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Select which session you will attend:<sup>*</sup></Form.Label>
                  <Form.Control as="select" value={this.state.rsvpPreferredTime} onChange={(event) => this.setState({ rsvpPreferredTime: event.target.value })}>
                    <option className="hideOption"></option>
                    <option disabled>Pick and day and time</option>
                    <option>Sunday, November 12th - 4:45 PM to 5:45 PM</option>
                    <option>Sunday, November 12th - 6:15 PM to 7:15 PM</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label># of Guests Attending:<sup>*</sup></Form.Label>
                  <Form.Control
                    type="number"
                    max="99"
                    pattern="[0-9]*"
                    placeholder="Enter number of guests attending"
                    value={this.state.rsvpAttendees}
                    onChange={(event) => this.setState({ rsvpAttendees: event.target.value.substring(0, 2) })}
                  />
                </Form.Group>
                <p>Please bring your confirmation email to check-in on the day of the event.<br />Thank you and Jay Swaminarayan.</p>
              </Form>
              : ""}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
              Close
            </Button>
            {!this.state.rsvpShowSuccessMsg ?
              <div>
                <Button variant="primary" onClick={() => this.handleRSVPRegister()} disabled={this.state.rsvpDisableRegisterBtn}>
                  Register Now
                </Button>
              </div>
              : ""}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
