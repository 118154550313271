// Vendor Files
import React from "react";
import { Button, Form, Table, Alert, Modal, InputGroup, Card } from 'react-bootstrap';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import { useParams } from "react-router";
import * as moment from 'moment-timezone';
import ReactToPrint from 'react-to-print';
import { PrinterFill, ArrowClockwise } from 'react-bootstrap-icons';

// Custom Components
import ViewFamilyMembers from "./ViewFamilyMembers"

// Image Files
import pencil_icon from "../../assets/images/pencil.png";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class PadharamniViewList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            familyFilterValue: "",
            filteredFamilyList: [],
            showEditModal: false,
            editFamilyDetails: {},
            hideTimes: true,
            availTimes: [],
            registerDisableBtn: false,
            registerWarningText: "",
            registerShowWarning: false,
            canModify: false
        };
    }

    componentDidMount() {
        if (!this.props.santTable) {
            this.setState({ filteredFamilyList: this.props.tableList, familyFilterValue: localStorage.getItem('filterFamilyValue') ? localStorage.getItem('filterFamilyValue') : "All" }, () => {
                this.filterFamily(this.state.familyFilterValue)
            });
        }
        else {
            this.setState({ filteredFamilyList: this.props.tableList, familyFilterValue: localStorage.getItem('filterFamilyValue') ? localStorage.getItem('filterFamilyValue') : moment().format('YYYY-MM-DD') });
        }
        localStorage.removeItem('filterFamilyValue');
    }

    editFamily(familyDetails) {
        this.handleResetValues();
        let family = { ...familyDetails };
        family.Date = family.Date ? family.Date : "";
        family.SelectedTime = family.SelectedTime ? family.SelectedTime : "";
        family.Notes = family.Notes ? family.Notes : "";
        this.setState({
            editFamilyDetails: family, showEditModal: true
        });
    }

    handleClose(save = false) {
        if (save) {
            localStorage.setItem('filterFamilyValue', this.state.familyFilterValue);
            if (this.props.santTable) {
                this.props.refreshList(this.state.familyFilterValue);
            }
            else {
                this.props.refreshList();
            }
        }
        this.setState({ showEditModal: false, editFamilyDetails: {} });
    }

    filterFamily(filterValue) {
        this.setState({ familyFilterValue: filterValue });

        if (filterValue === "All") {
            this.setState({ filteredFamilyList: this.props.tableList });
            return;
        }

        let filteredFamilies = this.props.tableList.filter(
            (family) => (family.Date === filterValue.split("|")[0] && family.Session === filterValue.split("|")[1] && family.SantName1 === filterValue.split("|")[2])
        );

        this.setState({ filteredFamilyList: filteredFamilies });
    }

    filterDate(dateVal) {
        this.setState({ familyFilterValue: dateVal }, () => {
            this.handleClose(true)
        });
    }

    handleResetValues() {
        this.setState({
            showEditModal: false,
            editFamilyDetails: {},
            registerDisableBtn: false,
            registerWarningText: "",
            registerShowWarning: false
        });
    }

    changeValue(value, changeItem) {
        let familyDetails = this.state.editFamilyDetails;
        if (changeItem === 1) {
            familyDetails.Notes = value;
        }
        else if (changeItem === 2) {
            familyDetails.PadharamniStatus = value;
        }
        this.setState({ editFamilyDetails: familyDetails });
    }

    handleRegister() {
        // let url = `http://localhost:5004/api/padharamni`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni` // Production
        let body = {
            eventName: this.props.padharamniList[0].EventName,
            personId: this.state.editFamilyDetails.PersonId,
            padharamniId: this.state.editFamilyDetails.PadhramniAvailabilityId,
            selectedTime: this.state.editFamilyDetails.SelectedTime,
            santName1: this.state.editFamilyDetails.SantName1,
            santName2: this.state.editFamilyDetails.SantName2,
            padharamniStatus: this.state.editFamilyDetails.PadharamniStatus,
            notes: this.state.editFamilyDetails.Notes
        }
        this.setState({ registerDisableBtn: true });
        axios.post(url, body).then((res) => {
            if (res.data.data.affectedRows > 0) {
                this.handleClose(true);
            }
            else {
                this.setState({ registerDisableBtn: false });
                this.setState({ registerWarningText: "An error occured while sending your information. Please try again.", registerShowWarning: true });
            }
        });
    }

    render() {
        return (
            <Accordion defaultActiveKey="familyTable">
                <Card>
                    <Card.Header className="printCont">
                        <span className="copyLink refresh" onClick={() => this.handleClose(true)}><ArrowClockwise></ArrowClockwise></span>
                        View Padharamnis ({this.state.filteredFamilyList.length})
                        <span></span>
                        <ReactToPrint
                            trigger={() => {
                                return <a href={void(0)} className="copyLink"><PrinterFill></PrinterFill></a>;
                            }}
                            content={() => this.componentRef}
                        />
                    </Card.Header>

                    <Accordion.Collapse eventKey="familyTable">
                        <Card.Body>
                            <Table striped className="padharamniTable tableDesktop" ref={el => (this.componentRef = el)}>
                                <thead className="filterResults">
                                    <tr>
                                        {!this.props.santTable && <th colSpan={3}>
                                            <InputGroup className="">
                                                <InputGroup.Text>
                                                    Filter by:
                                                </InputGroup.Text>
                                                <Form.Control as="select" value={this.state.familyFilterValue} onChange={(event) => this.filterFamily(event.target.value)}>
                                                    <option value="All">All</option>
                                                    {this.props.padharamniList.map((padharamni) => (
                                                        <option key={padharamni.Date + "|" + padharamni.Session + "|" + padharamni.SantName1} value={padharamni.Date + "|" + padharamni.Session + "|" + padharamni.SantName1}>{moment.utc(padharamni.Date).format('dddd').substring(0, 3) + ", " + moment.utc(padharamni.Date).format('LL')} | {padharamni.Session} | {padharamni.SantName1}</option>
                                                    ))}
                                                </Form.Control>
                                            </InputGroup>
                                        </th>}
                                        {this.props.santTable && <th colSpan={3}>
                                            <InputGroup className="">
                                                <InputGroup.Text>
                                                    Filter by:
                                                </InputGroup.Text>
                                                <Form.Control type="date" value={this.state.familyFilterValue} onChange={(event) => this.filterDate(event.target.value)} />
                                            </InputGroup>
                                        </th>}
                                    </tr>
                                </thead>

                                {this.props.santTable && <>
                                    <thead>
                                        <tr>
                                            <th>Info</th>
                                            <th>Karyakar 1</th>
                                            <th style={{ textAlign: "left" }}>Karyakar 2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.padharamniList.map((padharamni) => (
                                            <tr key={padharamni.Id}>
                                                <td>
                                                    <b>Date:</b> {moment.utc(padharamni.Date).format('dddd').substring(0, 3) + ", " + moment.utc(padharamni.Date).format('LL')}<br />
                                                    <b>Time:</b> {padharamni.Time}<br />
                                                    <b>Zone:</b> {padharamni.ZoneName}<br />
                                                    <b>ZC:</b> {padharamni.ZcName}<br />
                                                    <b>Phone:</b> <a href={`tel:${padharamni.ZcPhone}`}>{padharamni.ZcPhone}</a><br />
                                                    <b>ZCC:</b> {padharamni.ZccName}<br />
                                                    <b>Phone:</b> <a href={`tel:${padharamni.ZccPhone}`}>{padharamni.ZccPhone}</a>
                                                </td>
                                                <td>
                                                    <b>Name:</b> {padharamni.KaryakarName1}<br />
                                                    <b>Phone no:</b> <a href={`tel:${padharamni.KaryakarCell1}`}>{padharamni.KaryakarCell1}</a>
                                                </td>
                                                <td>
                                                    <b>Name:</b> {padharamni.KaryakarName2}<br />
                                                    <b>Phone no:</b> <a href={`tel:${padharamni.KaryakarCell2}`}>{padharamni.KaryakarCell2}</a>
                                                </td>
                                            </tr>
                                        ))}
                                        {this.props.padharamniList.length === 0 &&
                                            <tr>
                                                <td colSpan={3} className="tableNoResults">No results to display</td>
                                            </tr>
                                        }
                                    </tbody>
                                    <br />
                                    <br />
                                </>}

                                <thead>
                                    <tr>
                                        <th>Family Details</th>
                                        <th>Padharamni Info</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filteredFamilyList.map((family) => (
                                        <tr key={family.personId}>
                                            <td>
                                                <b>Name:</b> {family.FirstName + " " + family.LastName} <ViewFamilyMembers personId={family.PersonId} santTable={this.props.santTable} eventName={this.props.padharamniList[0].EventName} selEvent={this.props.selEvent}></ViewFamilyMembers><br />
                                                <b>Phone:</b> <a href={`tel:${family.Cell}`}>{family.Cell}</a><br />
                                                <b>Email:</b> {family.Email}<br />
                                                {family.Source && family.Source.length > 0 && <><b>Source:</b> {family.Source}<br /></>}
                                                {family.SOKaryakar && family.SOKaryakar.length > 0 && <><b>Sampark Karyakar:</b> <a href={`tel:${family.SOPhone}`}>{family.SOKaryakar}</a><br /></>}
                                                <b>Address:</b> <a rel="noreferrer" href={`https://maps.google.com/?q=${family.Address}, ${family.City}, ${family.State}, ${family.ZipCode}`} target="_blank">{family.Address + ", " + family.City}</a><br />
                                            </td>
                                            <td>
                                                {family.PadharamniStatus === "cancelled" ?
                                                    <><b>Cancelled:</b> {family.CancelReason}<br /></>
                                                    : <>
                                                        <b>Date:</b> {family.Date ? moment.utc(family.Date).format('dddd').substring(0, 3) + ", " + moment.utc(family.Date).format('LL') : ""}<br />
                                                        <b>Time:</b> {family.SelectedTime}<br />
                                                        {!this.props.santTable && <><b>Sant:</b> {family.SantName1}<br /></>}
                                                        <b>Completed:</b> {family.PadharamniStatus === "completed" ? "Yes" : "No"}<br />
                                                    </>}
                                                <b>Notes:</b> {family.Notes}
                                            </td>
                                            <td>{family.Date && <button onClick={() => this.editFamily(family)}><img src={pencil_icon} alt="Edit family details" /></button>}</td>
                                        </tr>
                                    ))}
                                    {this.state.filteredFamilyList.length === 0 &&
                                        <tr>
                                            <td colSpan={3} className="tableNoResults">No results to display</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>

                            <Table striped className="padharamniTable tableMobile">
                                <thead className="filterResults">
                                    <tr>
                                        {!this.props.santTable && <th colSpan={2}>
                                            <InputGroup className="">
                                                <InputGroup.Text>
                                                    Filter by:
                                                </InputGroup.Text>
                                                <Form.Control as="select" value={this.state.familyFilterValue} onChange={(event) => this.filterFamily(event.target.value)}>
                                                    <option value="All">All</option>
                                                    {this.props.padharamniList.map((padharamni) => (
                                                        <option key={padharamni.Date + "|" + padharamni.Session + "|" + padharamni.SantName1} value={padharamni.Date + "|" + padharamni.Session + "|" + padharamni.SantName1}>{moment.utc(padharamni.Date).format('dddd').substring(0, 3) + ", " + moment.utc(padharamni.Date).format('LL')} | {padharamni.Session} | {padharamni.SantName1}</option>
                                                    ))}
                                                </Form.Control>
                                            </InputGroup>
                                        </th>}
                                        {this.props.santTable && <th colSpan={2}>
                                            <InputGroup className="">
                                                <InputGroup.Text>
                                                    Filter by:
                                                </InputGroup.Text>
                                                <Form.Control type="date" value={this.state.familyFilterValue} onChange={(event) => this.filterDate(event.target.value)} />
                                            </InputGroup>
                                        </th>}
                                    </tr>
                                </thead>
                                {this.props.santTable && <>
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Padharamni Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.padharamniList.map((padharamni) => (
                                            <tr key={padharamni.Id} >
                                                <td colSpan="2">
                                                    <b>Date:</b> {moment.utc(padharamni.Date).format('dddd').substring(0, 3) + ", " + moment.utc(padharamni.Date).format('LL')}<br />
                                                    <b>Time:</b> {padharamni.Time}<br />
                                                    <b>Zone:</b> {padharamni.ZoneName}<br />
                                                    <b>ZC:</b> {padharamni.ZcName}<br />
                                                    <b>Phone:</b> <a href={`tel:${padharamni.ZcPhone}`}>{padharamni.ZcPhone}</a><br />
                                                    <b>ZCC:</b> {padharamni.ZccName}<br />
                                                    <b>Phone:</b> <a href={`tel:${padharamni.ZccPhone}`}>{padharamni.ZccPhone}</a>
                                                    <br /><br />
                                                    <b>Karykar Information:</b><br />
                                                    <b>Name:</b> {padharamni.KaryakarName1}<br />
                                                    <b>Phone no:</b> <a href={`tel:${padharamni.KaryakarCell1}`}>{padharamni.KaryakarCell1}</a><br /> <br />
                                                    <b>Name:</b> {padharamni.KaryakarName2}<br />
                                                    <b>Phone no:</b> <a href={`tel:${padharamni.KaryakarCell2}`}>{padharamni.KaryakarCell2}</a>
                                                </td>
                                            </tr>
                                        ))}
                                        {this.props.padharamniList.length === 0 &&
                                            <tr>
                                                <td colSpan={2} className="tableNoResults">No results to display</td>
                                            </tr>
                                        }
                                    </tbody>
                                    <br />
                                    <br />
                                </>}

                                <thead>
                                    <tr>
                                        <th>Family Details</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filteredFamilyList.map((family) => (
                                        <tr key={family.personId}>
                                            <td>
                                                <b>Name:</b> {family.FirstName + " " + family.LastName} <ViewFamilyMembers personId={family.PersonId} santTable={this.props.santTable} eventName={this.props.padharamniList[0].EventName}></ViewFamilyMembers><br />
                                                <b>Phone:</b> <a href={`tel:${family.Cell}`}>{family.Cell}</a><br />
                                                <b>Email:</b> {family.Email}<br />
                                                {family.Source && family.Source.length > 0 && <><b>Source:</b> {family.Source}<br /></>}
                                                {family.SOKaryakar && family.SOKaryakar.length > 0 && <><b>Sampark Karyakar:</b> <a href={`tel:${family.SOPhone}`}>{family.SOKaryakar}</a><br /></>}
                                                <b>Address:</b> <a rel="noreferrer" href={`https://maps.google.com/?q=${family.Address}, ${family.City}, ${family.State}, ${family.ZipCode}`} target="_blank">{family.Address + ", " + family.City}</a><br />
                                                <br />
                                                {family.PadharamniStatus === "cancelled" ?
                                                    <><b>Cancelled:</b> {family.CancelReason}<br /></>
                                                    : <>
                                                        <b>Date:</b> {family.Date ? moment.utc(family.Date).format('dddd').substring(0, 3) + ", " + moment.utc(family.Date).format('LL') : ""}<br />
                                                        <b>Time:</b> {family.SelectedTime}<br />
                                                        {!this.props.santTable && <><b>Sant:</b> {family.SantName1}<br /></>}
                                                        <b>Completed:</b> {family.PadharamniStatus === "completed" ? "Yes" : "No"}<br />
                                                    </>}
                                                <b>Notes:</b> {family.Notes}
                                            </td>
                                            <td>{family.Date && <button onClick={() => this.editFamily(family)}><img src={pencil_icon} alt="Edit family details" /></button>}</td>
                                        </tr>
                                    ))}
                                    {this.state.filteredFamilyList.length === 0 &&
                                        <tr>
                                            <td key="noResults" colSpan={2} className="tableNoResults">No results to display</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Modal show={this.state.showEditModal} onHide={() => this.handleClose()} size="md" className="modalCont" scrollable={true}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div>
                            <p>
                                <b>Name:</b> {this.state.editFamilyDetails.FirstName + " " + this.state.editFamilyDetails.LastName}<br />
                                <b>Address:</b> {this.state.editFamilyDetails.Address + ", " + this.state.editFamilyDetails.City}<br />
                                <b>Date:</b> {moment.utc(this.state.editFamilyDetails.Date).format('dddd').substring(0, 3) + ", " + moment.utc(this.state.editFamilyDetails.Date).format('LL')}<br />
                                <b>Time:</b> {this.state.editFamilyDetails.SelectedTime}<br />
                            </p>
                            {this.state.registerShowWarning ? <Alert variant={"danger"}>{this.state.registerWarningText}</Alert> : ""}
                            <Form.Group className="mb-3">
                                <Form.Label>Notes:</Form.Label>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    value={this.state.editFamilyDetails.Notes}
                                    onChange={(event) => this.changeValue(event.target.value, 1)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Padharamni Completed?</Form.Label>
                                <Form.Control as="select" value={this.state.editFamilyDetails.PadharamniStatus} onChange={(event) => this.changeValue(event.target.value, 2)}>
                                    <option value="completed">Yes</option>
                                    <option value="scheduled">No</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                            Close
                        </Button>
                        <div>
                            <Button variant="primary" onClick={() => this.handleRegister()} disabled={this.state.registerDisableBtn}>
                                Modify Now
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Accordion>
        );
    }
}

export default withParams(PadharamniViewList);
