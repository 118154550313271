// Vendor Files
import React from "react";
import { Form, Modal, Alert, Button } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router";

// Events List
import { bookingList } from './bookingList';

// Image Files

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Bookings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selEvent: {
                'name': '',
                'description': ''
            },
            showBookingModal: false,
            alreadyBooked: false,
            bookingPreferredDay: "",
            bookingPreferredTime: "",
            bookingAttendees: "",
            bookingReason: "",
            bookingPrivate: "",
            bookingNiyams: "",
            bookingCheckboxes: [],
            bookingShowFormWarning: false,
            bookingWarningText: "",
            bookingSuccessText: "",
            bookingDisableRegisterBtn: false,
            bookingDisableCancelBtn: false,
            bookingShowSuccessMsg: false,
            bookingCancelText: "We have successfully cancelled your registration. If you would like to reschedule please click button below.",
            selPerson: null,
            showForm: false,
            datesToShow: [],
            timesToShow: [],
            changeTime: false
        };
    }

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let personId = 0;
        if (params.get('id')) {
            personId = params.get('id');
        }

        let events = bookingList;
        // Open RSVP modal through url
        let { booking_name } = this.props.params;
        if (booking_name) {
            if (booking_name in events) {
                this.handleResetFormFields();
                if (params.get('date') && params.get('time')) {
                    this.setState({bookingPreferredDay: params.get('date'), bookingPreferredTime: params.get('time')});
                    this.setState({changeTime: true});
                }
                this.setState({selEvent: events[booking_name]});
                this.handleOpen();
                this.checkPerson(booking_name, personId);
            }
        }
    }

    checkPerson(booking_name, personId) {
        // let url = `http://localhost:5004/api/book/${booking_name}/${personId}`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/book/${booking_name}/${personId}` // Production
        axios.get(url).then((res) => {
            if (res.data.data.length > 0) {
                // person found
                this.setState({selPerson: res.data.data[0]});
                if (res.data.data[0].BookedDate) {
                    this.setState({showForm: false, alreadyBooked: true});
                }
                else {
                    this.setState({showForm: true, bookingPrivate: "No"});
                }
            }
            else {
                // person not found
                this.setState({selPerson: null, showForm: false, bookingShowFormWarning: true, bookingWarningText: "Record not found, please contact your SO Karyakar."});
            }
        }, (err) => {
            // this only runs on error
            this.setState({selPerson: null, showForm: false, bookingShowFormWarning: true, bookingWarningText: "Record not found, please contact your SO Karyakar."});
        });
    }

    handleDateSelection(event) {
        this.setState({ bookingPreferredDay: event.target.value, timesToShow: [] });
        let dateIndex = this.state.selPerson.AvailDate.split('|').indexOf(event.target.value);

        // let url = `http://localhost:5004/api/book/avail/${this.state.selPerson.EventName}/${event.target.value}`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/book/avail/${this.state.selPerson.EventName}/${event.target.value}` // Production
        axios.get(url).then((res) => {
            let bookedTimes = res.data.data;
            let availTimes = [];
            if (this.state.selEvent.breakTimes) {
                let all_times = this.state.selEvent.breakTimes;
                this.state.selPerson.AvailTime.split('|')[dateIndex].split(',').forEach(timeSlot => {
                    let timeRange = timeSlot.split("-").map((item) => {
                        return item.trim();
                    });
                    availTimes.push(...all_times.slice(all_times.indexOf(timeRange[0]), all_times.indexOf(timeRange[1])));
                })
                
            }
            else {
                availTimes = this.state.selPerson.AvailTime.split('|')[dateIndex].split(',');
            }
            let newTimes = []
            availTimes.forEach(time => {
                let totalSlots = this.state.selPerson.EventName.indexOf("samarpan-mulakat") === 0 ? 1 : time === "10:30 AM - 11:30 AM" ? 20 : 30;
                if (bookedTimes.filter((booking) => booking.BookedTime === time).length > 0) {
                    let bookedCount = bookedTimes.filter((booking) => booking.BookedTime === time)[0].Count;
                    let slotsleft = totalSlots - bookedCount;
                    if (slotsleft < 0) {
                        slotsleft = 0;
                    }
                    newTimes.push({
                        time: time,
                        slotsLeft: slotsleft
                    });
                }
                else {
                    newTimes.push({
                        time: time,
                        slotsLeft: totalSlots
                    });
                }
            });
            this.setState({timesToShow: newTimes});
        });
    }

    handleClose() {
        this.setState({
            showBookingModal: false,
            bookingShowFormWarning: false,
            bookingWarningText: "",
            bookingSuccessText: "",
            bookingDisableRegisterBtn: false,
            bookingDisableCancelBtn: false,
            bookingShowSuccessMsg: false,
            datesToShow: [],
            timesToShow: [],
            bookingCheckboxes: [],
            changeTime: false
        });
        this.handleResetFormFields();
    }

    handleOpen() {
        this.setState({ showBookingModal: true });
    }

    handleRSVPRegister() {
        if (parseInt(this.state.bookingAttendees) <= 0) {
            this.setState({ bookingWarningText: "# of Guests(male) must be a minimum of 1.", bookingShowFormWarning: true });
            return;
        }

        // Check fields are filled
        let unfilledFields = this.state.bookingPreferredDay === "" || this.state.bookingPreferredTime === "" || (this.state.selEvent.guestsAllowed && this.state.bookingAttendees === "");
        if (unfilledFields === true) {
            this.setState({ bookingWarningText: "Please fill out the required fields.", bookingShowFormWarning: true });
            return;
        }

        // let url = `http://localhost:5004/api/book/${this.state.selPerson.EventName}/${this.state.selPerson.PersonId}`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/book/${this.state.selPerson.EventName}/${this.state.selPerson.PersonId}` // Production
        let body = {
            eventName: this.state.selPerson.EventName,
            eventLocation: this.state.selEvent.location,
            emailSubject: this.state.selEvent.emailSubject,
            bcc: this.state.selEvent.bcc,
            bookedDate: this.state.bookingPreferredDay,
            bookedTime: this.state.bookingPreferredTime,
            attendees: this.state.selEvent.guestsAllowed ? this.state.bookingAttendees : 1,
            reason: this.state.bookingReason,
            private: this.state.bookingPrivate,
            niyams: this.state.bookingCheckboxes.join(", "),
            email: this.state.selPerson.Email
        }
        this.setState({ bookingDisableRegisterBtn: true });
        axios.post(url, body).then((res) => {
            if (res.data.data.affectedRows > 0) {
                this.setState({ bookingDisableRegisterBtn: false, bookingShowSuccessMsg: true, bookingSuccessText: "We have successfully registered you for the event, please check your email for more details.", bookingShowFormWarning: false });
                this.handleResetFormFields();
            }
            else {
                this.setState({ bookingDisableRegisterBtn: false });
                this.setState({ bookingWarningText: "An error occured while sending your information. Please try again.", bookingShowFormWarning: true });
            }
        });
    }

    handleDeleteRegistration() {
        // let url = `http://localhost:5004/api/book/${this.state.selPerson.EventName}/${this.state.selPerson.PersonId}`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/book/${this.state.selPerson.EventName}/${this.state.selPerson.PersonId}` // Production
        this.setState({ bookingDisableCancelBtn: true });
        axios.delete(url).then((res) => {
            if (res.data.data.affectedRows > 0) {
                this.setState({ bookingDisableCancelBtn: false, alreadyBooked: false, bookingShowSuccessMsg: true, bookingSuccessText: this.state.bookingCancelText, bookingShowFormWarning: false });
                this.handleResetFormFields();
            }
            else {
                this.setState({ bookingDisableCancelBtn: false });
                this.setState({ bookingWarningText: "An error occured while sending your information. Please try again.", bookingShowFormWarning: true });
            }
        });
    }

    handleCheckboxes(event, checkbox_name) {
        let checkbox_status = this.state.bookingCheckboxes;
        if (event.target.checked) {
            checkbox_status.push(checkbox_name);
        }
        else {
            checkbox_status.splice(checkbox_status.indexOf(checkbox_name), 1)
        }
        this.setState({bookingCheckboxes: checkbox_status});
    }

    handleResetFormFields() {
        this.setState({
            bookingPreferredDay: "",
            bookingPreferredTime: "",
            bookingAttendees: "",
            bookingReason: "",
            bookingPrivate: "",
            bookingNiyams: ""
        });
    }

    checkDateValidity(availDates) {
        return availDates.filter((availDate) => {return new Date(availDate + " 11:59 PM") >= new Date()});
    }
  
    render() {
        return (
            <Modal show={this.state.showBookingModal} onHide={() => this.handleClose()} size="lg" className="modalCont" scrollable={true}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="modalHeader">
                        <h2>{this.state.selEvent.displayName ? this.state.selEvent.displayName : this.state.selEvent.name}</h2>
                        {this.state.selEvent.location ? <p><b>Location:</b> {this.state.selEvent.location}</p> : ""}
                        <p>{this.state.selEvent.description}</p>
                        {this.state.selPerson ? 
                            <p>
                                <b>Name:</b> {this.state.selPerson.FullName} | <b>Email:</b> {this.state.selPerson.Email ? this.state.selPerson.Email : "N/A"} <br/>
                                <b>Zone:</b> {this.state.selPerson.ZoneName} | <b>BAPSID:</b> {this.state.selPerson.BAPSID ? this.state.selPerson.BAPSID : "N/A"}
                            </p>
                        : ""}
                    </div>

                    {!this.state.selEvent.active ? <Alert variant={"success"}>Thank you for joining us at our event! Your time and attention are truly appreciated.</Alert> : ""}
                    {this.state.alreadyBooked ? <Alert variant={"success"}>You have already booked a slot for {this.state.selPerson.BookedDate} at {this.state.selPerson.BookedTime}.<br/>If you would like to reschedule, please cancel and register again.</Alert> : ""}
                    {this.state.bookingShowFormWarning ? <Alert variant={"danger"}>{this.state.bookingWarningText}</Alert> : ""}
                    {this.state.bookingShowSuccessMsg ? <Alert variant={"success"}>{this.state.bookingSuccessText}</Alert> : ""}
                    {/* {!this.state.selPerson ? <Alert variant={"danger"}>Record not found, please contact your SO Karyakar.</Alert> : ""} */}

                    {!this.state.bookingShowSuccessMsg & this.state.selEvent.active && this.state.showForm ?
                        <Form autoComplete="disabled">
                            
                            <Form.Group className="mb-3">
                                <Form.Label>Select which day you will attend:<sup>*</sup></Form.Label>
                                <Form.Control as="select" disabled={this.state.changeTime} value={this.state.bookingPreferredDay} onChange={(event) => this.handleDateSelection(event)}>
                                    <option className="hideOption"></option>
                                    <option disabled>Pick a day</option>
                                    {this.state.changeTime ? <option>{this.state.bookingPreferredDay}</option> : ""}
                                    {this.checkDateValidity(this.state.selPerson.AvailDate.split('|')).map(eventDay => {
                                        return (
                                            <option>{eventDay}</option>
                                        )
                                    })}
                                    
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Select a time you will attend:<sup>*</sup></Form.Label>
                                <Form.Control as="select" disabled={!this.state.timesToShow.length > 0 || this.state.changeTime} value={this.state.bookingPreferredTime} onChange={(event) => this.setState({ bookingPreferredTime: event.target.value })}>
                                    <option className="hideOption"></option>
                                    <option disabled>Pick a time</option>
                                    {this.state.changeTime ? <option>{this.state.bookingPreferredTime}</option> : ""}
                                    {this.state.timesToShow.map(eventTime => {
                                        return (
                                            <option disabled={eventTime['slotsLeft'] <= 0} value={eventTime['time']}>{eventTime['time']} {!this.state.selEvent.breakTimes ? `(${eventTime['slotsLeft']} slots left)` : ""}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                            {this.state.selPerson.EventName.indexOf("samarpan-mulakat") === -1 ?
                            <>
                            <Form.Group className="mb-3">
                                <Form.Label># of Guests(male) Attending:<sup>*</sup></Form.Label>
                                <Form.Control
                                    type="number"
                                    max="99"
                                    pattern="[0-9]*"
                                    placeholder="Enter number of guests attending"
                                    value={this.state.bookingAttendees}
                                    onChange={(event) => this.setState({ bookingAttendees: event.target.value.substring(0,2) })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>What do you need aashirwad for? (success, business, job, education, personal life, etc.)</Form.Label>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    maxLength="255"
                                    rows={2}
                                    value={this.state.bookingReason}
                                    onChange={(event) => this.setState({ bookingReason: event.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Select any niyams you would like to take:</Form.Label>
                                <Form.Check>
                                    <Form.Check.Input id="check1" onChange={(event) => this.handleCheckboxes(event, "Nitya Puja / Mansi Puja")} type="checkbox" />
                                    <Form.Check.Label for="check1">Nitya Puja / Mansi Puja</Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                    <Form.Check.Input id="check2" onChange={(event) => this.handleCheckboxes(event, "Nitya Vanchan (Vachnamrut, Swami ni Vaato, Jivan Charitra)")} type="checkbox" />
                                    <Form.Check.Label for="check2">Nitya Vanchan (Vachnamrut, Swami ni Vaato, Jivan Charitra)</Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                    <Form.Check.Input id="check3" onChange={(event) => this.handleCheckboxes(event, "Weekly Gharsabha")} type="checkbox" />
                                    <Form.Check.Label for="check3">Weekly Gharsabha</Form.Check.Label>
                                </Form.Check>
                                <Form.Check>
                                    <Form.Check.Input id="check4" onChange={(event) => this.handleCheckboxes(event, "Sunday for Swami (Seva at Mandir)")} type="checkbox" />
                                    <Form.Check.Label for="check4">Sunday for Swami (Seva at Mandir)</Form.Check.Label>
                                </Form.Check>
                                {/* <Form.Check type="checkbox" label="Nitya Puja / Mansi Puja" onChange={(event) => this.handleCheckboxes(event, "Nitya Puja / Mansi Puja")}/>
                                <Form.Check type="checkbox" label="Nitya Vanchan (Vachnamrut, Swami ni Vaato, Jivan Charitra)" onChange={(event) => this.handleCheckboxes(event, "Nitya Vanchan (Vachnamrut, Swami ni Vaato, Jivan Charitra)")}/>
                                <Form.Check type="checkbox" label="Weekly Gharsabha" onChange={(event) => this.handleCheckboxes(event, "Weekly Gharsabha")}/>
                                <Form.Check type="checkbox" label="Sunday for Swami (Seva at Mandir)" onChange={(event) => this.handleCheckboxes(event, "Sunday for Swami (Seva at Mandir)")}/> */}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Do you need to have conversation in private?</Form.Label>
                                <Form.Control as="select" value={this.state.bookingPrivate} onChange={(event) => this.setState({ bookingPrivate: event.target.value })}>
                                    <option>No</option>
                                    <option>Yes</option>
                                </Form.Control>
                            </Form.Group>
                            </> : ""}
                        </Form>
                        : ""}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                    {!this.state.bookingShowSuccessMsg & this.state.selEvent.active & this.state.showForm ?
                        <div>
                            <Button variant="primary" onClick={() => this.handleRSVPRegister()} disabled={this.state.bookingDisableRegisterBtn}>
                                Register Now
                            </Button>
                        </div>
                        : ""}
                    {this.state.alreadyBooked ? <Button onClick={() => this.handleDeleteRegistration()} disabled={this.state.bookingDisableCancelBtn} variant="danger">Cancel Registration</Button> : ""}
                    {this.state.bookingSuccessText === this.state.bookingCancelText ? <Button variant="primary" onClick={() => window.location.reload()}>Register again</Button> : ""}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withParams(Bookings);