// Vendor Files
import React from "react";
import { Form, Modal, Alert, Button } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class RSVPSamarpan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selPerson: null,
            rsvpAvail: "",
            rsvpPreferredDay: "",
            rsvpShowFormWarning: false,
            rsvpWarningText: "",
            rsvpDisableRegisterBtn: false,
            rsvpShowSuccessMsg: false,
            rsvpSuccessText: "",
        };
    }

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (!params.get('id') || this.getPersonData(params.get('id'))) {
            this.handleClose();
        }
    }

    getPersonData(personId) {
        // let url = `http://localhost:5004/api/samarpan-sabha/rsvp/${personId}`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/samarpan-sabha/rsvp/${personId}` // Production
        axios.get(url).then((res) => {
            if (res.data.data.length > 0) {
                this.setState({selPerson: res.data.data[0]});
            }
            else {
                this.setState({ selPerson: null, rsvpWarningText: "Record not found.", rsvpShowFormWarning: true });
            }
        });
    }

    handleClose() {
        this.props.hideForm();
    }

    handleRSVPRegister() {
        // Check fields are filled
        let unfilledFields = this.state.rsvpAvail === "" || (this.state.rsvpAvail !== "I will not be able to attend" && this.state.rsvpPreferredDay === "");
        if (unfilledFields === true) {
            this.setState({ rsvpWarningText: "All fields are required.", rsvpShowFormWarning: true });
            return;
        }

        // let url = `http://localhost:5004/api/samarpan-sabha/rsvp/${this.state.selPerson.PersonId}` // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/samarpan-sabha/rsvp/${this.state.selPerson.PersonId}` // Production
        let body = {
            rsvp: this.state.rsvpAvail,
            name: this.state.selPerson.Name,
            email: this.state.selPerson.Email,
            date: this.state.rsvpAvail !== "I will not be able to attend" ? this.state.rsvpPreferredDay : ''
        }
        this.setState({ rsvpDisableRegisterBtn: true });
        axios.post(url, body).then((res) => {
            if (res.data.data.affectedRows > 0) {
                this.setState({
                    rsvpSuccessText: this.state.rsvpAvail === "I will not be able to attend" ? "We are sorry that you won't be able to attend." : "We have successfully registered you for the event, please check your email for more details.", 
                    rsvpDisableRegisterBtn: false, 
                    rsvpShowSuccessMsg: true, 
                    rsvpShowFormWarning: false 
                });
                this.handleResetFormFields();
            }
            else {
                this.setState({ rsvpDisableRegisterBtn: false });
                this.setState({ rsvpWarningText: "An error occured while sending your information. Please try again.", rsvpShowFormWarning: true });
            }
        });
    }

    handleResetFormFields() {
        this.setState({
            rsvpAvail: "",
            rsvpPreferredDay: ""
        });
    }

    render() {
        return (
            <Modal show={true} onHide={() => this.handleClose()} size="lg" className="modalCont" scrollable={true}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="modalHeader">
                        <h2>{this.props.formData.displayName}</h2>
                        {this.state.selPerson && 
                            <p>
                                <b>Time:</b> 7:00 PM - 8:30 PM<br/>
                                <b>Location:</b> {this.props.formData.location} 
                                <br/><br/>
                                <b>Name:</b> {this.state.selPerson.Name} <br/>
                                <b>Zonename:</b> {this.state.selPerson.Zonename} | <b>Email:</b> {this.state.selPerson.Email} <br/>
                            </p>
                        }
                    </div>
                    {this.state.rsvpShowFormWarning ? <Alert variant={"danger"}>{this.state.rsvpWarningText}</Alert> : ""}
                    {this.state.rsvpShowSuccessMsg ? <Alert variant={"success"}>{this.state.rsvpSuccessText}</Alert> : ""}
                    {!this.state.rsvpShowSuccessMsg && this.state.selPerson &&
                        <Form autoComplete="disabled">
                            <Form.Group className="mb-3">
                                <Form.Label>Provide your availability:<sup>*</sup></Form.Label>
                                <Form.Control as="select" value={this.state.rsvpAvail} onChange={(event) => this.setState({rsvpAvail: event.target.value})}>
                                    <option className="hideOption"></option>
                                    <option disabled>Pick your availability</option>
                                    <option >I and my spouse both will attend</option>
                                    <option >I will attend</option>
                                    <option >I will not be able to attend</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Select which day you will attend:{this.state.rsvpAvail === "I will not be able to attend" ? "" : <sup>*</sup>}</Form.Label>
                                <Form.Control as="select" value={this.state.rsvpPreferredDay} onChange={(event) => this.setState({ rsvpPreferredDay: event.target.value })} disabled={this.state.rsvpAvail === "I will not be able to attend"}>
                                    <option className="hideOption"></option>
                                    <option disabled>Pick and day and time</option>
                                    {this.props.formData.eventDays.map(eventDay => {
                                        return (
                                            <option>{eventDay}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                    {!this.state.rsvpShowSuccessMsg && this.state.selPerson &&
                        <div>
                            <Button variant="primary" onClick={() => this.handleRSVPRegister()} disabled={this.state.rsvpDisableRegisterBtn}>
                                Register Now
                            </Button>
                        </div>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withParams(RSVPSamarpan);