// Vendor Files
import React from "react";
import { Container, Button } from 'react-bootstrap';


// Image Files

export default class NotFoundContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="sectionCont">
                    <Container>
                        <h1 className="title">Sorry, we couldn't find that page.</h1>
                        <p>If you would like to learn more about our activities and upcoming events, please click below to learn more.</p>
                        <br />
                        <Button className="defaultBtn" href="/">More Details</Button>
                    </Container>
                </div>
            </React.Fragment >
        );
    }
}