// Vendor Files
import React from "react";
import * as moment from 'moment-timezone';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button } from 'react-bootstrap';
import { useParams } from "react-router";

// Custom Components
import Header from "../components/Header"
import Footer from "../components/Footer"
import Booking from "../components/Bookings/Bookings"

// Image Files
// import diwali_mainpage from "../assets/images/diwali_mainpage.jpg";
import yt_red from "../assets/logos/youtube-icon-red.png";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      err_events: false,
      curr_link: "",
      showBooking: false
    };
  }

  componentDidMount() {

    // Show Booking
    if (window.location.pathname.split('/')[1].toLowerCase() === 'book') {
      this.setState({showBooking: true});
    }

    fetch(
      "https://sheets.googleapis.com/v4/spreadsheets/1UA2J4aItyrzG9_sxDJI5xuH-ldnQDjbxD34G1Rty_3o/values/Announcements?key=AIzaSyBv5LPMYVjJ0gttyVyROpQD5xO0nvMy2So"
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.values.length > 1) {
            let events = result.values;
            events.shift();
            for (let i = 0; i < events.length; i++) {

              // empty event
              if (events[i] === "" || events[i][0] === "") {
                events.splice(i, 1);
                i--;
                continue;
              }

              let endTime = new Date(events[i][1] + " " + events[i][3]);
              let currTime = new Date();
              if (endTime < currTime) {
                events.splice(i, 1);
                i--;
              }
              else if (events[i][6] !== undefined && events[i][6] === 'N') {
                events.splice(i, 1);
                i--;
              }

            }

            if (events.length > 0) {
              this.setState({
                events: events
              });

              var nowLocal = moment();
              var checkEastern = moment.tz("America/New_York");

              // If Eastern
              if (nowLocal.utcOffset() === checkEastern.utcOffset() && events[0][5] !== "") {
                this.setState({
                  curr_link: events[0][5]
                });
              }
              else {
                this.setState({
                  curr_link: events[0][4]
                });
              }

            }

            // Check if it's arti time
            // let curr_time = moment(new Date());
            // let curr_day = curr_time.tz('America/Chicago').day(); // make sure its not a sunday
            // curr_time = curr_time.tz('America/Chicago').format('H:mm z');

            // // Shangar Arti
            // let arti_start_shangar = "06:30 CST";
            // let arti_end_shangar = "07:55 CST";
            // if (curr_time > arti_start_shangar && curr_time < arti_end_shangar) {
            //   this.setState({
            //     curr_link: "https://www.youtube.com/embed/live_stream?channel=UCkGpeKga6fGlyyLatxp_Qvg"
            //   });
            // }

            // // Sandhya Arti
            // let arti_start_sandhya = "18:00 CST";
            // let arti_end_sandhya = "19:25 CST";
            // if (curr_time > arti_start_sandhya && curr_time < arti_end_sandhya && curr_day !== 7) {
            //   this.setState({
            //     curr_link: "https://www.youtube.com/embed/live_stream?channel=UCkGpeKga6fGlyyLatxp_Qvg"
            //   });
            // }

            // Display next webcast automatically if no specific link is present in sheets
            if (this.state.curr_link === "" || this.state.curr_link === undefined) {
              this.setState({
                curr_link: "https://www.youtube.com/embed/live_stream?channel=UCkGpeKga6fGlyyLatxp_Qvg"
              });
            }
          }
        },
        (error) => {
          this.setState({
            err_events: true,
          });
        }
      );
  }

  render() {
    return (
      <div>

        <Header metaPage="home"></Header>

        {this.state.curr_link !== "" ? (
          <div className="sectionCont">
            <Container>
            <h1 className="title">Upcoming Webcast</h1>
            <div className="youtube-video-container">
              <div className="youtube-video-ratio">
                <iframe
                  className="youtube-video"
                  src={this.state.curr_link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Live Broadcast"
                ></iframe>
              </div>
              <div style={{ justifyContent: "center", marginTop: "20px" }}>
                <p className="youtube-app-link-description">
                  This webcast is also viewable on
                </p>
                <a
                  href="https://www.youtube.com/channel/UCkGpeKga6fGlyyLatxp_Qvg"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#136cce",
                    textDecoration: "none",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center", margin: "0px 20px" }} >
                    <img
                      className="youtube-app-icon"
                      srcSet={yt_red + " 64w"}
                      sizes="300px"
                      src={yt_red}
                      alt="YouTube App"
                    />
                    <h3 className="youtube-app-desc">BAPS Midwest</h3>
                  </div>
                </a>
              </div>
            </div>
            <br/><br/>
            <p>Your consideration to support our cause and activities is much appreciated. Click below if you would like to further support us through various sponsorship&nbsp;opportunities.</p>
            <Button target="_blank" className="btn-main" href="http://donate.na.baps.org/chicago">Sponsorship Opportunities</Button>
            </Container>
          </div>

        ) : (
          ""
        )}

        {this.state.curr_link !== "" ? (
          <div className="divider-line"></div>
        ) : ""}
{/* 
        <div className="sectionCont eventBanner">
          <Container>
            <h2>Diwali Celebrations</h2>
            <div className={"gridCont"}>
              <Row>
                <Col md={5} className="imgCol">
                  <img className="grid-event-img" src={diwali_mainpage} alt="BAPS" />
                </Col>
                <Col md={7} className="gridCol">
                  <p>BAPS Shri Swaminarayan Mandir, Chicago, IL cordially invites you, your family and friends to celebrate Diwali & the Hindu New Year! <br /><br /> Click below to learn more about the festivities that will take place throughout October and November as we celebrate the Hindu New Year.</p>
                  <Button href="/diwali">More Details</Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <div className="divider-line"></div> */}

        <div className="sectionCont">
          <Container>
          <h2>Announcements</h2>
          <div id="announcements">
            {this.state.err_events ? (
              <p>
                We are having issues at the moment. Please try again later.
              </p>
            ) : (
              <div>
                <h3 className="">Daily Webcast</h3>
                <p className="announcement-times">
                  <span className="event">Shangar Arti &amp; Satsang</span>
                  <span className="mobile-break">
                    <br></br>
                  </span>
                  <span style={{ fontWeight: "bold", color: "#5a5a5a" }}>
                    <span className="time-separator">&nbsp;|&nbsp;</span>
                    Monday - Sunday{" "}
                  </span>
                  7:30 AM CST
                </p>
                <p className="announcement-times">
                  <span className="event">Sandhya Arti &amp; Satsang</span>
                  <span className="mobile-break">
                    <br></br>
                  </span>
                  <span style={{ fontWeight: "bold", color: "#5a5a5a" }}>
                    <span className="time-separator">&nbsp;|&nbsp;</span>
                    Monday - Saturday{" "}
                  </span>
                  7:00 PM CST
                </p>
                {this.state.events.map((event, index) => (
                  <div
                    key={event[0] + event[1]}
                  >
                    {index === 0 ? <h3 className="">Next Webcast</h3> : ""}
                    {index === 1 ? (
                      <h3 className="">Upcoming Webcasts</h3>
                    ) : (
                      ""
                    )}
                    <p className="announcement-times">
                      <span className="event">
                        {event[0]}
                      </span>
                      <span className="mobile-break">
                        <br></br>
                      </span>
                      <span style={{ fontWeight: "bold", color: "#5a5a5a" }}>
                        <span className="time-separator">&nbsp;|&nbsp;</span>
                        {event[1]}{" "}
                      </span>
                      {event[2]}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <h3>Note</h3>
          <p className="announcement-times">
            Further times will be added in due course.
          </p>
          <p className="announcement-times" style={{ marginTop: 0 }}>
            All days and times are subject to change.
          </p>
          </Container>
        </div>

        {this.state.showBooking ? <Booking></Booking> : ""}
        
        <Footer></Footer>
      </div>
    );
  }
}

export default withParams(Home);
