import React from 'react';
import ReactDOM from "react-dom"
import { hydrate, render } from "react-dom";
import Home from './pages/Home';
import "./style.scss";
import reportWebVitals from './reportWebVitals';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

// Custome Pages
import Diwali from "./pages/Diwali";
import TimelessHinduWisdom from "./pages/TimelessHinduWisdom";
import NotFound from "./pages/NotFound";
import CheckInPage from './pages/CheckIn/CheckInPage';
import Events from "./pages/Events/Events";
// import CampusDiwali from "./pages/CampusDiwali";
import Padharamni from "./pages/Padharamni/PadharamniMain";


const App = (
  <Router>
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="book/:booking_name" element={<Home />}></Route>
      <Route path="timelesshinduwisdom" element={<TimelessHinduWisdom />}></Route>
      <Route path="checkin/:event_name" element={<CheckInPage />}></Route>
      <Route path="diwali" element={<Diwali />}></Route>
      <Route path="thw" element={<TimelessHinduWisdom />}></Route>
      <Route path="events" element={<Events />}></Route>
      <Route path="events/:event_name" element={<Events />}></Route>
      {/* <Route path="campus-diwali" element={<CampusDiwali />}></Route> */}
      <Route path="padharamni/:center_name/:event_name" element={<Padharamni />}></Route>
      <Route path='*' element={<NotFound />}/>
    </Routes>
  </Router>
);

// ReactDOM.render(routing, document.getElementById("root"));
ReactDOM.render(App, document.getElementById('root'));
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(App, rootElement);
} else {
  render(App, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
