export const zoneList = {
    'Chicago': [
        { 'id': 'Addison', 'pin': '5689' }, { 'id': 'Aurora', 'pin': '5678' }, { 'id': 'Bartlett - East', 'pin': '9012' }, { 'id': 'Bartlett - West', 'pin': '3456' }, { 'id': 'Buffalo Grove', 'pin': '7890' }, { 'id': 'Carol Stream', 'pin': '2345' }, { 'id': 'Chicago - Devon', 'pin': '6789' }, { 'id': 'Chicago - Downtown', 'pin': '1230' }, { 'id': 'Chicago - IIT', 'pin': '4567' }, { 'id': 'Chicago - Lawrence', 'pin': '8901' }, { 'id': 'Chicago - Midway', 'pin': '1357' }, { 'id': 'Chicago - Ohare', 'pin': '2468' }, { 'id': 'Des Plaines - Skokie', 'pin': '3791' }, { 'id': 'Elgin', 'pin': '8642' }, { 'id': 'Elk Grove Village', 'pin': '5802' }, { 'id': 'Glendale Heights', 'pin': '6924' }, { 'id': 'Gurnee', 'pin': '7150' }, { 'id': 'Hanover Park', 'pin': '9246' }, { 'id': 'Hoffman Estates', 'pin': '2847' }, { 'id': 'Hindi Sabha', 'pin': '3422' }, { 'id': 'Joliet', 'pin': '4321' }, { 'id': 'Kuhn Rd', 'pin': '5432' }, { 'id': 'Naperville', 'pin': '9876' }, { 'id': 'Palatine', 'pin': '1098' }, { 'id': 'Rockford', 'pin': '3210' }, { 'id': 'Schaumburg', 'pin': '7531' }, { 'id': 'St_Charles', 'pin': '9867' }, { 'id': 'Streamwood', 'pin': '4570' }, { 'id': 'West Chicago', 'pin': '1346' }, { 'id': 'Westmont', 'pin': '6028' }
    ],
    'Bloomington': [
        { 'id': 'Bloomington', 'pin': '4729' }, { 'id': 'Peoria', 'pin': '8361'}, { 'id': 'Champaign', 'pin': '2947' }, { 'id': 'Springfield', 'pin': '5297' }
    ],
    'Crystal Lake': [
        { 'id': 'Crystal Lake', 'pin': '5821' }
    ],
    'Evansville': [
        { 'id': 'Evansville', 'pin': '9430' }, { 'id': 'Owensboro', 'pin': '8123' }
    ],
    'Indianapolis': [
        { 'id': 'Avon', 'pin': '1673' }, { 'id': 'Carmel', 'pin': '3289' }, { 'id': 'Greenwood', 'pin': '7542' }, { 'id': 'Fishers', 'pin': '6815' }, { 'id': 'Remote-Indy', 'pin': '4906' }
    ],
    'Munster': [
        { 'id': 'Munster', 'pin': '2374' }, { 'id': 'Orland Park', 'pin': '4759' }, { 'id': 'Michigan City', 'pin': '3968' }
    ],
    'Iowa City': [
        { 'id': 'Iowa City', 'pin': '8614' }
    ]
};