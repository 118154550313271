export const eventsList = {
    'student-seminar': {
        'name': 'student-seminar',
        'displayName': 'Think Different. Be Different. Succeed Different.',
        'eventDate': 'Thursday, August 17, 2023',
        'eventTime': '7:00 PM - 9:00 PM',
        'active': false
    },
    'campus-diwali': {
        'name': 'campus-diwali',
        'displayName': 'Diwali Inspires',
        'eventDate': 'Thursday, November 16, 2023',
        'eventTime': '7:00 PM - 9:00 PM',
        'active': false
    },
    'ssm24': {
        'name': 'ssm24',
        'displayName': 'Satsang Suhrad Milan',
        'eventDate': 'Friday, January 19, 2024 | Friday, January 26, 2024',
        'eventTime': '5:30 PM - 9:00 PM',
        'active': false
    },
    'student-sabha': {
        'name': 'student-sabha-aug',
        'displayName': 'Student Sabha',
        'eventDate': 'Thursday, August 29th, 2024',
        'eventTime': '7:00 PM - 9:00 PM',
        'active': true
    },
    'uic-student-sabha': {
        'name': 'uic-student-sabha-sep',
        'displayName': 'Student Sabha',
        'eventDate': 'Thursday, September 5th, 2024',
        'eventTime': '7:00 PM - 9:00 PM',
        'active': true
    },
    'hindi-sabha': {
        'name': 'hindi-sabha-july',
        'displayName': 'Hindi Sabha',
        'eventDate': 'Sunday, July 14th, 2024',
        'eventTime': '4:00 PM - 5:30 PM',
        'active': true
    },

    // Samarpan Events
    'samarpan-sabha': {
        'name': 'samarpan-sabha-aug-22',
        'displayName': 'Samarpan Sabha',
        'eventDate': 'Thursday, August 22nd, 2024',
        'eventTime': '7:00 PM - 8:30 PM',
        'notEmail': true,
        'active': true
    },
};